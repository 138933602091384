import NitroAdContainer from "./NitroAdContainer";

const BoxDynamicAd = ({ className, container }) => {
  return (
    <div className={className} style={{ minHeight: 600 }}>
      <NitroAdContainer
        container={container}
        id="sidebar"
        adProps={{
          sizes: [
            ["300", "250"],
            ["336", "280"],
            ["160", "600"],
            ["300", "600"],
            ["320", "480"],
            ["320", "100"],
            ["320", "50"],
          ],
          mediaQuery:
            "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px)",
        }}
      ></NitroAdContainer>
    </div>
  );
};

export default BoxDynamicAd;
