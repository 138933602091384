import { useContext, useEffect, useRef, useState } from "react";
import config from "../../config";
import { Alert, Button, Card, CardBody, CardTitle, Form, FormGroup, Input, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import truckyService from "../../common/services/trucky-service";
import 'leaflet/dist/leaflet.css';
import '../components/scss/map.scss';
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import truckyapiClientService from "../../common/services/truckyapi-client-service";
require('linqjs');
const io = require('socket.io-client');
const moment = require('moment');

window.io = io;
window.moment = moment;

const LiveMap = ({ container }) => {

    const { globalContext } = useContext(EnterpriseContext);
    const mapManager = useRef();
    const [currentSelectedUser, setCurrentUser] = useState(null);
    const [servers, setServers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [currentSelectedServer, setCurrentServer] = useState(null);
    const [checkingOnlineState, setCheckingOnlineState] = useState(false);
    const [isUserOffline, setIsUserOffline] = useState(null);
    const [showIDs, setShowIDs] = useState(false);
    const [showNames, setShowNames] = useState(false);
    const [game, setGame] = useState('ets2');
    const [permalink, setPermalink] = useState('');
    const [filtersCollapsed, setFiltersCollapsed] = useState(true);

    const [mapContainerHeight, setMapContainerHeight] = useState(window.innerHeight - 110);

    const params = new URLSearchParams(window.location.search);

    useEffect(() => {

        truckyService.setDocumentTitle('Live Map');

        if (params.has('game'))
            setGame(params.get('game'));

        window.addEventListener('resize', () => {
            setMapContainerHeight(window.innerHeight - 110);
        });

        window.addEventListener('orientationchange', () => {
            setMapContainerHeight(window.innerHeight - 110);
        });

        return () => {
            mapManager.current.stopRefresh();
            mapManager.current.destroyMap();
            mapManager.current.disconnectSocket();
            mapManager.current = null;
        };
    }, []);

    useEffect(() => {
        if (mapManager.current) {
            mapManager.current.setShowNames(showNames);
            mapManager.current.setShowIDs(showIDs);
        }
        // change map settings
    }, [showIDs, showNames]);

    useEffect(() => {
        if (game != null)
            console.log(`Game is changed: ${game}`);
        loadMap();
    }, [game]);

    const loadMap = async () => {

        if (servers.length == 0)
            await getMapServers();

        await loadMapJs();

        truckyService.writeLog('MapManager injected');

        let defaultOptions = {
            game: game, mapContainerID: 'liveMapContainer',
            onPlayerFollow: onPlayerFollow,
            onMapChangeLocation: onMapDrag
        };

        if (!mapManager.current) {
            mapManager.current = window.mapManager = new MapManager(defaultOptions);
        }
        else {
            mapManager.current.disconnectSocket();
            mapManager.current.stopRefresh();
            truckyService.writeLog('Switching game map');
            mapManager.current.updateGame(game);
            truckyService.writeLog('Destroying map');
            mapManager.current.destroyMap();
        };

        truckyService.writeLog('Init map');

        mapManager.current.initMap(() => {
            truckyService.writeLog('Live Map is Ready');

            getLocations();

            buildPermalink();

            if (params.has('serverid')) {
                mapManager.current.setServerID(params.get('serverid'));
            }

            if (params.has('follow')) {
                onPlayerFollow(params.get('follow'));
                followPlayer(params.get('follow'));
            }

            if (params.has('ne') && params.has('sw')) {
                var neParams = params.get('ne').split('|');
                var swParams = params.get('sw').split('|');

                mapManager.current.map.fitBounds([
                    [neParams[0], neParams[1]],
                    [swParams[0], swParams[1]],
                ], { maxZoom: params.get('zoom') });
            }
        });
    }

    const onPlayerFollow = async (truckMpId) => {
        console.log(truckMpId);

        const tmpPlayer = await truckyapiClientService.getTruckersMPUser(truckMpId);

        if (tmpPlayer) {
            setCurrentUser(tmpPlayer);
        }
    }

    const loadMapJs = async () => {
        return new Promise((resolve, reject) => {

            if (document.getElementById('mapManagerJs') != null) {
                truckyService.writeLog('MapManager is already injected');
                resolve();
                return;
            }

            truckyService.writeLog('Injecting MapManager');

            const el = document.createElement('script');

            el.src = config.MAP_MANAGER_URL + '?v=' + new Date().getTime();
            el.id = "mapManagerJs"
            el.async = true;
            el.onload = resolve;
            el.onerror = reject;

            document.body.appendChild(el);
        });
    }

    const getMapServers = async () => {
        const result = await truckyapiClientService.getMapServers();
        setServers(result);
    }

    const fillCitiesDropdown = () => {
        var groupedCities = locations.where((c) => { return c.layerType == 'marker' && c.country != undefined }).groupBy((c) => { return c.country && c.country != null && c.country.realName });

        return groupedCities.map((c) => {

            return (
                <optgroup label={c.key}>
                    {c.map((m) => {
                        return <option key={m._id} value={m._id}>{m.realName}</option>
                    })};
                </optgroup>
            );
        });
        //debugger;
    }

    const getLocations = async () => {
        const result = await truckyapiClientService
            .getAllLayers(mapManager.current.mapConfiguration.game, mapManager.current.mapConfiguration.mod);
        setLocations(result.response);
    }

    const followMe = async () => {
        //debugger;

        const tmpPlayer = await truckyapiClientService.getTruckersMPUser(globalContext.member.steam.external_user_id);

        if (tmpPlayer) {
            setCheckingOnlineState(true);

            const onlineResponse = await truckyapiClientService.isOnline(tmpPlayer.id);

            setCheckingOnlineState(false);

            if (onlineResponse.response.online) {
                setIsUserOffline(false);
                setCurrentUser(tmpPlayer);
                mapManager.current.followPlayer(tmpPlayer.id);
            } else
                setIsUserOffline(true);
        }
    }

    const followPlayer = async (player_id) => {
        const onlineResponse = await truckyapiClientService.isOnline(player_id);

        if (onlineResponse.response.online) {
            mapManager.current.followPlayer(player_id);
            changeServer(onlineResponse.response.server);
        }
    }

    const changeServer = (server_id, refreshMap) => {
        const server = servers.find(m => m.id.toString() == server_id.toString());

        if (server) {
            let game = server.game.toLowerCase();

            if (server.game == 'ETS2' && server.mod == 'promods')
                game = 'promods';

            setGame(game);
            setCurrentServer(server);
            mapManager.current.setServerID(server_id);
            buildPermalink();
            setFiltersCollapsed(true);
        }
    }

    const goToCity = (id) => {
        const location = locations.find(m => m._id == id);
        mapManager.current.goToLocation(location.gameCoordinates.x, location.gameCoordinates.y);
        setFiltersCollapsed(true);
    }

    const onMapDrag = () => {
        buildPermalink();
    }

    const buildPermalink = () => {
        if (mapManager.current && mapManager.current.map) {
            const bounds = mapManager.current.map.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            const zoom = mapManager.current.map.getZoom();

            setPermalink(`?ne=${ne.lat}|${ne.lng}&sw=${sw.lat}|${sw.lng}&zoom=${zoom}&serverid=${mapManager.current.serverID}&game=${game}`);
        }
    }

    const renderBoxes = () => {
        return (
            <div className="min-w-300px" style={{ position: 'absolute', top: 10, left: 10, zIndex: 99 }}>
                <div className="mb-3 mx-1 d-flex align-items-center">
                    <Button onClick={() => setFiltersCollapsed(!filtersCollapsed)} className="me-3"><FontAwesomeIcon icon="filter" className="mx-0"></FontAwesomeIcon></Button>
                    {globalContext.member != null &&
                        <>
                            <Button
                                disabled={checkingOnlineState}
                                color="primary"
                                onClick={followMe}
                            >
                                {!checkingOnlineState &&
                                    <FontAwesomeIcon className="m-0" icon="location-crosshairs" />
                                }
                                {checkingOnlineState && (
                                    <FontAwesomeIcon
                                        icon="spinner"
                                        spin
                                        className="mx-0"
                                    />
                                )}
                            </Button>

                        </>
                    }
                    {permalink != '' &&
                        <div className="ms-3">
                            <a className="text-white" href={permalink}>Permalink</a>
                        </div>
                    }
                </div>
                {isUserOffline === true && (
                    <Alert color="danger" className="mt-3 w-300px">
                        {container.localeManager.strings.offline}
                    </Alert>
                )}
                <Card className={!filtersCollapsed ? "" : "d-none"}>
                    <CardBody>
                        <FormGroup>
                            <Label>{container.localeManager.strings.servers}</Label>
                            <Input
                                value={currentSelectedServer?.id}
                                onChange={e => changeServer(e.target.value.toString(), true)}
                                type="select"
                            >
                                {servers.map(m => {
                                    return (
                                        <option key={m.id} value={m.id.toString()}>
                                            {m.game} - {m.shortname} - {m.name} ({m.trucks})
                                        </option>
                                    );
                                })}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>{container.localeManager.strings.places}</Label>
                            <Input
                                onChange={e => goToCity(e.target.value)}
                                type="select"
                            >
                                {fillCitiesDropdown()}
                            </Input>
                        </FormGroup>
                        <FormGroup check className="mb-3">
                            <Label check>
                                <Input
                                    defaultValue={showIDs}
                                    onChange={e =>
                                        setShowIDs(e.target.checked)
                                    }
                                    type="checkbox"
                                />{" "}
                                {container.localeManager.strings.showID}
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    defaultValue={showNames}
                                    onChange={e =>
                                        setShowNames(e.target.checked)
                                    }
                                    type="checkbox"
                                />{" "}
                                {container.localeManager.strings.showName}
                            </Label>
                        </FormGroup>
                    </CardBody>
                </Card>
            </div>);
    }

    return (
        <>
            <div className="border rounded position-relative livemap-container" style={{ height: mapContainerHeight.toString() + 'px' }}>
                {renderBoxes()}
                <div className="" style={{ height: "100%" }} id="liveMapContainer">
                </div>
                {currentSelectedUser != null && (
                    <>
                        <Card className="map-player-container mt-3 w-300px d-none d-md-block" style={{ position: 'absolute', bottom: 15, left: 15, zIndex: 99 }}>
                            <CardBody>
                                <CardTitle className="d-flex justify-content-between align-items-center">
                                    <div>
                                        {container.localeManager.strings.profileDetails}
                                    </div>
                                    <div>
                                        <Button color="transparent px-0"><FontAwesomeIcon className="mx-0" icon="times" onClick={() => setCurrentUser(null)}></FontAwesomeIcon></Button>
                                    </div>
                                </CardTitle>
                                <div className="text-center mb-3">
                                    <img
                                        className="avatar rounded h-100px"
                                        src={currentSelectedUser.avatar}
                                    />
                                </div>
                                <div className="text-center mb-3">
                                    <a
                                        className="profile-name"
                                        target="_blank"
                                        href={
                                            "https://truckersmp.com/user/" + currentSelectedUser.id
                                        }
                                    >
                                        {currentSelectedUser.name}
                                    </a>
                                </div>
                                <div className="player-id text-center">
                                    {currentSelectedUser.id}
                                </div>
                                <div className="text-center">
                                    {currentSelectedUser.groupName}
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="d-md-none d-flex w-250px flex-row" style={{ position: 'absolute', bottom: 15, left: 15, zIndex: 99 }}>
                            <CardBody>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-row">
                                        <img
                                            className="avatar rounded h-30px"
                                            src={currentSelectedUser.avatar}
                                        />
                                        <div className="d-inline ms-3">
                                            <a
                                                className="profile-name"
                                                target="_blank"
                                                href={
                                                    "https://truckersmp.com/user/" + currentSelectedUser.id
                                                }
                                            >
                                                {currentSelectedUser.name}
                                            </a>
                                            <br />
                                            {currentSelectedUser.id} - {currentSelectedUser.groupName}
                                        </div>
                                    </div>
                                    <div>
                                        <Button color="transparent px-0"><FontAwesomeIcon className="mx-0" icon="times" onClick={() => setCurrentUser(null)}></FontAwesomeIcon></Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </>
                )}
            </div>
        </>
    )
}

export default LiveMap;