import React, { Component, PureComponent } from "react";

class TruckyIcon extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <i style={this.props.style} className={"ti ti-" + this.props.icon + " " + (this.props.className ? this.props.className : '')}></i>
        )
    }
}

export default TruckyIcon;