import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TruckyIcon from "../TruckyIcon";

export default function UserAttributes({ user, container }) {
  return (
    <div className="flex items-center">     
      {user.steam_profile && (
        <a
          href={`http://steamcommunity.com/profiles/${user.steam_profile.steam_id}`}
          target="_blank"
          className="text-white me-5"
        >
          <FontAwesomeIcon
            icon={["fab", "steam"]}
            className="me-1"
          ></FontAwesomeIcon>
          {container.localeManager.strings.steamProfile}
        </a>
      )}
      {user.discord_profile && (
        <span className="me-5">
          <FontAwesomeIcon
            icon={["fab", "discord"]}
            className="me-1"
          ></FontAwesomeIcon>
          {user.discord_profile.discord_username}
        </span>
      )}
      {user.is_premium && (
        <span className="text-premium me-5">
          <FontAwesomeIcon icon="star" className="me-1"></FontAwesomeIcon>
          Premium
        </span>
      )}
      {user.is_ambassador && (
        <span className="text-primary me-5">
          <FontAwesomeIcon icon="shield" className="me-1"></FontAwesomeIcon>
          Ambassador
        </span>
      )}
      {user.is_staff && (
        <span className="text-success">
          <TruckyIcon icon="logo" className="me-1"></TruckyIcon>
          Trucky Staff
        </span>
      )}
    </div>
  );
}
