import { useContext, useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import CustomBadge from "../../components/ui/CustomBadge";
import truckyService from "../../common/services/trucky-service";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import NitroAdContainer from "../../ads/NitroAdContainer";

const Homepage = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const es = new EnterpriseService();
  const [stats, setStats] = useState(null);
  const [companyStats, setCompanyStats] = useState(null);
  const navigate = useNavigate();
  const loginUrl = `${
    config.ENTERPRISE_URL
  }/authentication/login/steam?returnUrl=auth${encodeURIComponent(
    `?returnUrl=${window.location.pathname}`
  )}`;

  useEffect(() => {
    document.title = "Trucky - The Virtual Trucker Companion App";
  }, []);

  useEffect(() => {
    es.getOverallStats().then(setStats);
    es.getGlobalCompanyStats().then(setCompanyStats);
  }, []);

  const getCompanyLogo = (company) => {
    if (company.avatar_url == null)
      return (
        <img
          class="mb-3"
          style={{ maxHeight: 100, alignSelf: "center" }}
          src="assets/company-placeholder.png"
        />
      );
    else
      return (
        <img
          class="mb-3"
          style={{ maxHeight: 100, alignSelf: "center" }}
          src={company.avatar_url}
          onError={(el) => (el.src = "assets/company-placeholder.png")}
        />
      );
  };

  const getCompanyStyleBadge = (company) => {
    if (company.company_type == "realistic")
      return (
        <CustomBadge size="small" color="primary">
          {container.localeManager.strings.realisticEconomy}{" "}
          <FontAwesomeIcon className="ms-1" icon="users"></FontAwesomeIcon>
          {company.members_count}
        </CustomBadge>
      );

    if (company.company_type == "miles")
      return (
        <CustomBadge size="small" color="primary">
          {container.localeManager.strings.enterpriseMiles}{" "}
          <FontAwesomeIcon className="ms-1" icon="users"></FontAwesomeIcon>
          {company.members_count}
        </CustomBadge>
      );

    if (company.company_type == "both")
      return (
        <CustomBadge size="small" color="primary">
          {container.localeManager.strings.ecoAndMiles}{" "}
          <FontAwesomeIcon className="ms-1" icon="users"></FontAwesomeIcon>
          {company.members_count}
        </CustomBadge>
      );
  };

  return (
    <div>
      {stats != null && (
        <div className="grid cols-4">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fs-2 fw-bolder">
                  {container.localeManager.strings.drivers}
                </span>
                <FontAwesomeIcon
                  icon="users"
                  className="rounded bg-light p-3 fs-3"
                ></FontAwesomeIcon>
              </div>
              <div className="mt-10 fs-1 fw-bolder text-warning">
                {container.localeManager.numeral(stats.total_members).format()}
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fs-2 fw-bolder">
                  {container.localeManager.strings.companies}
                </span>
                <FontAwesomeIcon
                  icon="building"
                  className="rounded bg-light p-3 fs-3"
                ></FontAwesomeIcon>
              </div>
              <div className="mt-10 fs-1 fw-bolder text-warning">
                {container.localeManager.numeral(stats.companies).format()}
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fs-2 fw-bolder">
                  {container.localeManager.strings.deliveries}
                </span>
                <FontAwesomeIcon
                  icon="shipping-fast"
                  className="rounded bg-light p-3 fs-3"
                ></FontAwesomeIcon>
              </div>
              <div className="mt-10 fs-1 fw-bolder text-warning">
                {container.localeManager.numeral(stats.total_jobs).format()}
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fs-2 fw-bolder">
                  {container.localeManager.strings.distance}
                </span>
                <FontAwesomeIcon
                  icon="route"
                  className="rounded bg-light p-3 fs-3"
                ></FontAwesomeIcon>
              </div>
              <div className="mt-10 fs-1 fw-bolder text-warning">
                {container.localeManager
                  .numeral(stats.overall.distance_driven_on_job)
                  .format()}{" "}
                km
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      <div className="my-5 d-none d-lg-block">
        <Card>
          <CardBody>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <span className="fs-1 fw-bolder">
                  {container.localeManager.strings.getTrucky}
                </span>
                <div
                  className="mt-3"
                  dangerouslySetInnerHTML={{
                    __html: container.localeManager.strings.getTruckyInfo,
                  }}
                ></div>
              </div>
              <div>
                <Button
                  href="https://truckyapp.com/kb/install-the-overlay/"
                  target="_blank"
                  tag="a"
                  color="success"
                >
                  <FontAwesomeIcon icon="download"></FontAwesomeIcon>
                  {container.localeManager.strings.download}
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      {globalContext.member == null && (
        <div className="my-5">
          <Card>
            <CardBody className="text-center">
              <div className="fs-2 my-5">
                {container.localeManager.strings.registerWithSteam}
              </div>
              <Button
                color="primary"
                href={loginUrl}
                className="fw-bolder mb-5"
              >
                <FontAwesomeIcon icon={["fab", "steam"]}></FontAwesomeIcon>
                {container.localeManager.strings.continueWithSteam}
              </Button>
            </CardBody>
          </Card>
        </div>
      )}
      <NitroAdContainer
        container={container}
        id="hp-mid-2"
        adProps={{
          sizes: [
            ["728", "90"],
            ["970", "90"],
            ["970", "250"],
            ["300", "250"],
          ],
          mediaQuery:
            "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px)",
        }}
      ></NitroAdContainer>
      <NitroAdContainer
        container={container}
        id="hp-mid-2-mobile"
        adProps={{
          sizes: [
            ["300", "250"],
            ["320", "100"],
            ["320", "50"],
          ],
          mediaQuery: "(min-width: 320px) and (max-width: 767px)",
        }}
      ></NitroAdContainer>
      <div className="align-items-center d-flex flex-column align-content-center justify-content-center mt-10">
        {companyStats != null && (
          <div className="fs-2 mb-5 text-center fw-bolder w-100">
            {container.localeManager.formatString(
              container.localeManager.strings.joinClaim,
              companyStats.count
            )}
            <div className="mt-5 grid cols-4">
              {companyStats.companies.map((m) => {
                return (
                  <Card className="directory-company-card text-white">
                    <CardBody className="text-center d-flex flex-column">
                      <Link to={`/vtc/${m.slug}`}>
                        {getCompanyLogo(m)}
                        <CardTitle className="text-uppercase font-weight-bold">
                          <h4>{m.name}</h4>
                        </CardTitle>
                        <p className="mt-1" class="font-italic">
                          {m.slogan}
                        </p>
                        <div className="mt-auto">
                          {m.flag_url != null && (
                            <img src={m.flag_url} className="me-1" />
                          )}
                          {getCompanyStyleBadge(m)}
                        </div>
                      </Link>
                    </CardBody>
                  </Card>
                );
              })}
            </div>
          </div>
        )}
        <div className="d-flex flex-row gap-4">
          {(globalContext.member == null ||
            (globalContext.member != null &&
              globalContext.member.company_id == null)) && (
            <Button color="success" onClick={() => navigate("/company/create")}>
              <FontAwesomeIcon icon="rocket"></FontAwesomeIcon>
              {container.localeManager.strings.registerYourCompany}
            </Button>
          )}
          {(globalContext.member == null ||
            (globalContext.member != null &&
              globalContext.member.company_id == null)) && (
            <Button color="primary" onClick={() => navigate("/directory")}>
              <FontAwesomeIcon icon="search"></FontAwesomeIcon>
              {container.localeManager.strings.searchACompany}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
