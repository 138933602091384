import { useEffect, useRef, useState } from "react";
import config from "../config";
import { useLocation } from "react-router";

export default function NitroAdContainer({
  id,
  adProps,
  style,
  className,
  container,
}) {
  const defaultNitroAdProps = {
    demo: config.NITRO_ADS_DEMO, // REMOVE THIS OR SET TO FALSE FOR PRODUCTION
    refreshTime: 30,
    renderVisibleOnly: true,
    sizes: [],
    report: {
      enabled: true,
      icon: true,
      wording: "Report Ad",
      position: "bottom-right",
    },
  };

  const location = useLocation();
  const [initialPath, setInitialPath] = useState(location.pathname);

  const adContainer = useRef();
  const adReference = useRef();

  // this is called at every location change, included the first navigation
  useEffect(() => {
    if (container.dataService.data.enablePremiumFeature()) return;

    // if there is an ad initialized, call teh onNavigate on the ad instance
    if (adReference.current) {
      adReference.current.onNavigate();
      console.log(
        `Navigation change with Ad already created: called ${id} onNavigate`
      );
    } else {
      // otherwise, create the ad for the first time
      createAd();
    }
  }, [location]);

  const createAd = async () => {

    if (container.dataService.data.enablePremiumFeature())
      return;
    
    console.log(`Creating Ad ${id}`);
    // store the ad instance as ref so it's not affected from re-renders
    adReference.current = await window["nitroAds"].createAd(id, {
      ...defaultNitroAdProps,
      ...adProps,
    });
  };

  if (container.dataService.data.enablePremiumFeature()) return <></>;
  else {
    return (
      <>
        <div
          ref={adContainer}
          className={className}
          style={style}
          id={id}
        ></div>
      </>
    );
  }
}
