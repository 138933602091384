import { useEffect, useState } from "react";
import EnterpriseService from "../common/services/enterprise-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Spinner } from "reactstrap";
import { confirmWrapper } from "./ui/askConfirm";
import LoadingOverlay from "react-loading-overlay";
import config from "../config";
import { useSearchParams } from "react-router-dom";

export default function UserConnections({ container }) {
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const es = new EnterpriseService();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    getConnections();
  }, []);

  const getConnections = async () => {
    setLoading(true);
    const result = await es.getUserConnections();
    setConnections(result);
    setLoading(false);
  };

  const unlinkAccount = async (service, user_id) => {
    if (
      await confirmWrapper("Are you sure to unlink this account?", {
        container,
      })
    ) {
      setLoading(true);
      await es.unlinkSocialConnection(service, user_id);
      getConnections();
    }
  };

  return (
    <div class="py-2">
      <LoadingOverlay
        active={loading}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        {searchParams.get("service") &&
          searchParams.get("status") == "success" && (
            <div className="alert alert-success mb-5 d-flex items-align-center">
              <FontAwesomeIcon icon="check" size="2x"></FontAwesomeIcon> {searchParams.get("service")} has been connected successfully.
            </div>
          )}
        {searchParams.get("service") &&
          searchParams.get("status") == "error" && (
            <div className="alert alert-danger mb-5 d-flex items-align-center">
              <FontAwesomeIcon icon="warning" size="2x"></FontAwesomeIcon> {searchParams.get("error")}
            </div>
          )}
        {!loading && (
          <div className="mb-5 d-flex">
            {!connections.find((m) => m.service == "patreon") && (
              <Button
                className="me-3"
                tag="a"
                href={`${
                  config.ENTERPRISE_URL
                }/authentication/login/patreon?returnUrl=${encodeURIComponent(
                  "profile/edit?tab=connections&service=Patreon"
                )}`}
                color="patreon"
              >
                <FontAwesomeIcon icon={["fab", "patreon"]}></FontAwesomeIcon>{" "}
                Connect Patreon Account
              </Button>
            )}
            {!connections.find((m) => m.service == "discord") && (
              <Button
                tag="a"
                className="me-3"
                href={`${
                  config.ENTERPRISE_URL
                }/authentication/login/discord?returnUrl=${encodeURIComponent(
                  "profile/edit?tab=connections&service=Discord"
                )}`}
                color="discord"
              >
                <FontAwesomeIcon icon={["fab", "discord"]}></FontAwesomeIcon>{" "}
                Connect Discord Account
              </Button>
            )}
          </div>
        )}
        {connections.map((m) => {
          return (
            <>
              <div class="d-flex flex-stack">
                <div class="d-flex align-items-center">
                  <div className="ms-3 me-3">
                    {m.service == "steam" && (
                      <FontAwesomeIcon
                        fixedWidth
                        className="m-0"
                        icon={["fab", "steam"]}
                        size="3x"
                      ></FontAwesomeIcon>
                    )}
                    {m.service == "patreon" && (
                      <FontAwesomeIcon
                        fixedWidth
                        className="m-0"
                        icon={["fab", "patreon"]}
                        size="3x"
                      ></FontAwesomeIcon>
                    )}
                    {m.service == "google" && (
                      <FontAwesomeIcon
                        fixedWidth
                        className="m-0"
                        icon={["fab", "google"]}
                        size="3x"
                      ></FontAwesomeIcon>
                    )}
                    {m.service == "discord" && (
                      <FontAwesomeIcon
                        fixedWidth
                        className="m-0"
                        icon={["fab", "discord"]}
                        size="3x"
                      ></FontAwesomeIcon>
                    )}
                  </div>
                  <div class="d-flex flex-column">
                    <div class="fs-5 fw-bold">
                      {m.service == "steam" && <span>Steam</span>}
                      {m.service == "patreon" && <span>Patreon</span>}
                      {m.service == "google" && <span>Google</span>}
                      {m.service == "discord" && <span>Discord</span>}
                    </div>
                    {m.service != "patreon" && (
                      <div class="fs-6 fw-semibold text-gray-500">
                        {m.user_id}
                      </div>
                    )}
                    {m.service == "patreon" && m.patreon_data != null && (
                      <div class="fs-6 fw-semibold text-gray-500">
                        <div>
                          {m.user_id} - {m.patreon_data.full_name}
                        </div>
                        {m.patreon_data.tier_title != null && (
                          <div>
                            Tier: {m.patreon_data.tier_title} -{" "}
                            {m.patreon_data.last_charge_status}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center">
                  {m.service != "steam" && (
                    <Button
                      onClick={() => unlinkAccount(m.service, m.user_id)}
                      color="danger"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                      Unlink
                    </Button>
                  )}
                </div>
              </div>
              <div class="separator separator-dashed my-5"></div>
            </>
          );
        })}
      </LoadingOverlay>
    </div>
  );
}
