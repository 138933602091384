import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { useContext, useState } from "react";
import { useEffect } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import LoadingOverlay from "react-loading-overlay";
import EnterpriseNavBar from "./EnterpriseNavBar";
import { EnterpriseContext } from "./EnterpriseContext";
import Paginator from "../ui/Paginator";
import { useNavigate } from "react-router";
import Tips from "../Tips";
import { Link } from "react-router-dom";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import truckyService from "../../common/services/trucky-service";
import VTCMenu from "../../web/components/VTCMenu";

const Maintenances = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const company = globalContext.company;

  const es = new EnterpriseService();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    truckyService.setDocumentTitle("Maintenances");
  }, []);

  useEffect(() => {
    getTransactions();
  }, [pagination]);

  const getTransactions = async () => {
    setLoading(true);
    const result = await es.getMaintenances(company, pagination);
    setList(result.data);
    setTotalPages(result.last_page);
    setLoading(false);
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        <VTCMenu  active={'/maintenances'} container={container}></VTCMenu>
        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <h5>{container.localeManager.strings.maintenanceEvents}</h5>
                  {!loading && list.length > 0 && (
                    <>
                      <div className="mb-3">
                        <Paginator
                          forcePage={pagination.page - 1}
                          totalPages={totalPages}
                          handlePageClick={handlePageClick}
                        />
                      </div>
                      <Row>
                        <Col>
                          <Table
                            responsive
                            striped
                            size="sm"
                            className="table-row-dashed table-row-gray-300 align-middle gs-0"
                            hover
                          >
                            <thead>
                              <tr className="fw-bolder text-gray-700 bg-light">
                                <th scope="col" className="ps-4">
                                  {container.localeManager.strings.date}
                                </th>
                                <th scope="col">
                                  {container.localeManager.strings.vehicle}
                                </th>
                                <th scope="col">
                                  {container.localeManager.strings.type}
                                </th>
                                <th scope="col">
                                  {container.localeManager.strings.price}
                                </th>
                                <th scope="col">
                                  {container.localeManager.strings.odometer}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {list.map((m) => {
                                return (
                                  <tr>
                                    <td>
                                      {container.localeManager
                                        .moment(m.created_at)
                                        .format("l")}
                                    </td>
                                    <td>
                                      {m.vehicle != null && (
                                        <Link to={`/vehicle/${m.vehicle.id}`}>
                                          {m.vehicle.model.brand.name}{" "}
                                          {m.vehicle.model.name}
                                        </Link>
                                      )}
                                    </td>
                                    <td className="ps-2">
                                      {container.localeManager.strings[m.type]}
                                    </td>
                                    <td>
                                      {container.localeManager
                                        .numeral(m.price)
                                        .format("0,0")}{" "}
                                      {m.currency}
                                    </td>
                                    <td>
                                      {container.localeManager
                                        .numeral(m.odometer)
                                        .format("0,0")}{" "}
                                      {company.preferred_distance_unit}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              <Tips localeManager={container.localeManager}></Tips>
              {!window.overwolf && (
                <BoxDynamicAd container={container} className="my-3" />
              )}
            </aside>
          </Row>
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default Maintenances;
