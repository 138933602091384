import NitroAdContainer from "./NitroAdContainer";

export default function NitroAdSidebar({ container }) {
  return (
    <NitroAdContainer
      container={container}
      id="sidebar-sticky-stack"
      style={{ minHeight: 600, height: 600 }}
      adProps={{
        format: "sticky-stack",
        stickyStackLimit: 15,
        stickyStackSpace: 2.5,
        stickyStackOffset: 25,
        stickyStackOffset: 100,
        sizes: [
          ["300", "250"],
          ["160", "600"],
          ["300", "600"],
        ],
        mediaQuery:
          "(min-width: 1025px), (min-width: 320px) and (max-width: 767px)",
      }}
    ></NitroAdContainer>
  );
}
