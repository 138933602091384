import { useContext, useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import { useParams } from "react-router";
import { Alert, Card, CardBody, Spinner, Table } from "reactstrap";
import { Link } from "react-router-dom";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { EnterpriseContext } from "../../components/enterprise/EnterpriseContext";
import truckyService from "../../common/services/trucky-service";

export default function DispatchDetail({ container }) {
  const es = new EnterpriseService();
  const { globalContext } = useContext(EnterpriseContext);
  const [dispatches, setDispatches] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const { unique_id } = useParams();
  const { loading, setLoading } = useState(true);

  useEffect(() => {
    truckyService.setDocumentTitle(`Dispatch details ${unique_id}`);
    getData();
  }, []);

  const getData = async () => {
    const result = await es.getDispatchByUniqueOrGroupedUniqueID(unique_id);

    if (result && result.length > 0) {
      setDispatches(result);
    } else {
      setErrorMessage("No Dispatches found with this Unique ID");
    }
    setLoading(false);
  };
  return (
    <div>
      {loading && <Spinner />}
      {!loading && errorMessage != "" && (
        <Alert color="danger">{errorMessage}</Alert>
      )}
      {!loading && dispatches && dispatches.length > 0 && (
        <Card>
          <CardBody>
            <h2>Dispatch details for {unique_id}</h2>
            <div className="mt-5 d-flex">
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src={dispatches[0].dispatcher.avatar_url}
                    className="w-50px rounded"
                  />
                </div>
                <div className="ms-3">
                  <Link
                    target="_blank"
                    to={`/user/${dispatches[0].dispatcher.id}`}
                  >
                    {dispatches[0].dispatcher.name}
                  </Link>
                </div>
              </div>
              {dispatches[0].dispatcher.company != null && (
                <div className="d-flex align-items-center ms-5">
                  <div>
                    <img
                      src={dispatches[0].dispatcher.avatar_url}
                      className="w-50px rounded"
                    />
                  </div>
                  <div className="ms-3">
                    <Link
                      target="_blank"
                      to={`/company/${dispatches[0].dispatcher.company.id}`}
                    >
                      {dispatches[0].dispatcher.company.name}
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <Table
              responsive
              striped
              size="sm"
              className="table-row-dashed table-row-gray-300 align-middle gs-0 mt-5"
              hover
            >
              <thead>
                <tr className="fw-bolder text-gray-700 bg-light">
                  <th className="text-center">#</th>
                  <th className="text-start">
                    {container.localeManager.strings.sourceCity}
                  </th>
                  <th>{container.localeManager.strings.sourceCompany}</th>
                  <th>{container.localeManager.strings.destinationCity}</th>
                  <th>{container.localeManager.strings.destinationCompany}</th>
                  <th>{container.localeManager.strings.cargo}</th>
                  <th className="text-end pe-3">
                    {container.localeManager.strings.distance}
                  </th>
                </tr>
              </thead>
              <tbody>
                {dispatches.map((m) => {
                  return (
                    <tr>
                      <td className="text-center">{m.unique_id}</td>
                      <td>{m.data.source_city_name}</td>
                      <td>{m.data.source_company_name}</td>
                      <td>{m.data.destination_city_name}</td>
                      <td>{m.data.destination_company_name}</td>
                      <td>{m.data.cargo_name}</td>
                      <td className="text-end pe-3">
                        {UserDefinedUnits.convertDistance(
                          globalContext.member,
                          m.game.toLowerCase(),
                          m.data.distance
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      )}
    </div>
  );
}
