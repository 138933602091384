import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EnterpriseContext } from "./EnterpriseContext";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { confirmWrapper } from "../ui/askConfirm";
import Paginator from "../ui/Paginator";
import truckyService from "../../common/services/trucky-service";
import VTCMenu from "../../web/components/VTCMenu";

const NewsFeedManager = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);
  const es = new EnterpriseService();
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParameters, setSearchParameters] = useState({
    title: "",
    pinned: "all",
  });
  const [parameters, setParameters] = useState({ page: 1, perPage: 25 });
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    truckyService.setDocumentTitle("Company Feeds");
  }, []);

  useEffect(() => {
    getFeed();
  }, [parameters.page]);

  const getFeed = async () => {
    setLoading(true);
    const result = await es.getFeed(globalContext.company.id, searchParameters);
    setFeed(result.data);
    setTotalPages(result.last_page);
    setLoading(false);
  };

  const deleteFeed = async (id) => {
    if (
      await confirmWrapper(container.localeManager.strings.areYouSure, {
        container,
      })
    ) {
      await es.deleteFeed(globalContext.company.id, id);
      toast.success("Feed deleted");
      getFeed();
    }
  };

  const handlePageClick = (event) => {
    setParameters({ ...parameters, page: event.selected + 1 });
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        <Row noGutters>
          <VTCMenu  active={'/feeds/manage'} container={container}></VTCMenu>
          <Col>
            <Card>
              <CardBody>
                <h5 className="d-inline-block w-100 mb-3">
                  {container.localeManager.strings.newsFeed}
                  <div className="float-end">
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => navigate("/feeds/edit")}
                    >
                      <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                      {container.localeManager.strings.add}
                    </Button>
                  </div>
                </h5>
                <FormGroup row className="mb-3">
                  <Col sm={8}>
                    <Label>{container.localeManager.strings.title}</Label>
                    <Input
                      type="text"
                      value={searchParameters.title}
                      onChange={(e) =>
                        setSearchParameters({
                          ...searchParameters,
                          title: e.target.value,
                        })
                      }
                    ></Input>
                  </Col>
                  <Col sm={2}>
                    <Label>{container.localeManager.strings.pinned}</Label>
                    <Input
                      type="select"
                      value={searchParameters.pinned}
                      onChange={(e) =>
                        setSearchParameters({
                          ...searchParameters,
                          pinned: e.target.value,
                        })
                      }
                    >
                      <option value="all">
                        {container.localeManager.strings.all}
                      </option>
                      <option value="onlyPinned">
                        {container.localeManager.strings.onlyPinned}
                      </option>
                    </Input>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <Button color="primary" onClick={getFeed}>
                      {container.localeManager.strings.search}
                    </Button>
                  </Col>
                </FormGroup>
                <div className="my-3">
                  <Paginator
                    forcePage={parameters.page - 1}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                  />
                </div>
                <Table
                  responsive
                  striped
                  size="sm"
                  className="table-row-dashed table-row-gray-300 align-middle gs-0"
                  hover
                >
                  <thead>
                    <tr className="fw-bolder text-gray-700 bg-light">
                      <th scope="col" className="ps-4">
                        {container.localeManager.strings.title}
                      </th>
                      <th scope="col">
                        {container.localeManager.strings.author}
                      </th>
                      <th scope="col">
                        {container.localeManager.strings.published_at}
                      </th>
                      <th scope="col" className="text-center">
                        {container.localeManager.strings.membersOnly}
                      </th>
                      <th scope="col" className="text-center">
                        {container.localeManager.strings.pinned}
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {feed.map((m) => {
                      return (
                        <tr>
                          <td className="ps-4">{m.title}</td>
                          <td>{m.author.name}</td>
                          <td>
                            {container.localeManager
                              .moment(m.published_at)
                              .format("L")}
                          </td>
                          <td className="text-center">
                            {m.members_only ? (
                              <FontAwesomeIcon icon="check-circle"></FontAwesomeIcon>
                            ) : (
                              <FontAwesomeIcon icon="minus-circle"></FontAwesomeIcon>
                            )}
                          </td>
                          <td className="text-center">
                            {m.pinned ? (
                              <FontAwesomeIcon icon="check-circle"></FontAwesomeIcon>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <Button
                              title={container.localeManager.strings.edit}
                              onClick={() => navigate(`/feeds/edit/${m.id}`)}
                              className="btn-icon w-30px h-30px ms-auto"
                              size="sm"
                              color="active-light-primary"
                            >
                              <FontAwesomeIcon
                                icon="pencil-alt"
                                className="me-0"
                              ></FontAwesomeIcon>
                            </Button>
                            <Button
                              title={container.localeManager.strings.delete}
                              onClick={() => deleteFeed(m.id)}
                              className="btn-icon w-30px h-30px ms-auto"
                              size="sm"
                              color="active-light-danger"
                            >
                              <FontAwesomeIcon
                                icon="trash-alt"
                                className="me-0"
                              />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="my-3">
                  <Paginator
                    forcePage={parameters.page - 1}
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {!container.dataService.data.enablePremiumFeature() && (
            <aside className="ad-column-placeholder"></aside>
          )}
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LoadingOverlay>
    </>
  );
};

export default NewsFeedManager;
