import { useContext, useState } from "react";
import { useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import LoadingButton from "../ui/LoadingButton";

import { confirmWrapper } from "../ui/askConfirm";
import { EnterpriseContext } from "./EnterpriseContext";
import { useNavigate } from "react-router";
import Paginator from "../ui/Paginator";
import Tips from "../Tips";
import tipsList from "../../common/constants/tipsList";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import OrderByButtons from "../ui/OrderByButtons";
import truckyService from "../../common/services/trucky-service";
import ButtonDropdownMenu from "../ui/ButtonDropdownMenu";
import Select from "react-select";

import { fas } from "@fortawesome/free-solid-svg-icons";
import CustomBadge from "../ui/CustomBadge";
import UserDefinedUnits from "../../common/utils/UserDefinedUnits";
import { Link } from "react-router-dom";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import VTCMenu from "../../web/components/VTCMenu";
import config from "../../config";

const classnames = require("classnames");
require("linqjs");

const availableColumns = [
  "id",
  "member",
  "role",
  "revenue",
  "distance",
  "mass",
  "steam",
  "points",
  "level",
  "last_job_days",
  "tags",
  "total_earned",
  "roleSalary",
];

const defaultColumns = ["id", "member", "role", "revenue", "distance", "mass"];

const Members = ({ eventBus, container }) => {
  const { globalContext, setGlobalContext } = useContext(EnterpriseContext);

  const company = globalContext.company;
  const member = globalContext.member;

  const [loading, setLoading] = useState({
    members: false,
    roles: false,
    vehicles: false,
    jobs: false,
    permissions: false,
  });
  const es = new EnterpriseService();
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showRolesModal, toggleRoleModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  const [totalPages, setTotalPages] = useState(0);
  const [searchParameters, setSearchParameters] = useState(
    truckyService.loadSearchParameters("enteprise_members", {
      name: "",
      role_id: "",
      sortingField: "roles.order",
      sortingDirection: "ASC",
      inactive: false,
      steam_id: "",
      steam_username: "",
      withSteam: true,
    })
  );
  const [userChangeRole, setUserChangeRole] = useState(null);
  const [showChangeRoleModal, toggleChangeRoleModal] = useState(false);
  const [newRole, setNewRole] = useState(null);
  const [currentTag, setCurrentTag] = useState({});
  const [tags, setTags] = useState([]);
  const [showTagsModal, toggleTagsModal] = useState(false);
  const [icons, setIcons] = useState([]);
  const [iconSearchText, setIconSearchText] = useState("");
  const [columns, setColumns] = useState(
    truckyService.loadSearchParameters("members_table", defaultColumns)
  );
  const [discordRoles, setDiscordRoles] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    truckyService.setDocumentTitle("Members");
  }, []);

  useEffect(() => {
    truckyService.saveSearchParameters("enteprise_members", searchParameters);
  }, [searchParameters]);

  useEffect(() => {
    getRoles();
    getTags();
  }, []);

  useEffect(() => {
    getMembers();
  }, [
    pagination,
    searchParameters.sortingField,
    searchParameters.sortingDirection,
  ]);

  useEffect(() => {
    if (showRolesModal) {
      if (permissions.length == 0) getPermissions();

      if (config.ENABLE_DISCORD_INTEGRATION) {
        getDiscordRoles();
      }
    }
  }, [showRolesModal]);

  const getDiscordRoles = async () => {
    try {
      const result = await es.getCompanyDiscordServerRoles(company);

      if (result.success) {
        setDiscordRoles(result.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    toggleChangeRoleModal(userChangeRole != null);
    if (userChangeRole != null) {
      setNewRole(userChangeRole.role_id);
    }
  }, [userChangeRole]);

  useEffect(() => {
    if (showTagsModal) {
      searchIcons("a");

      if (tags.length == 0) getTags();

      if (config.ENABLE_DISCORD_INTEGRATION) {
        getDiscordRoles();
      }
    }
  }, [showTagsModal]);

  useEffect(() => {
    truckyService.saveSearchParameters("members_table", columns);
  }, [columns.length]);

  const searchIcons = (text) => {
    setIcons(
      Object.keys(fas)
        .filter((key) => fas[key].iconName.search(text) > -1)
        .slice(0, 30)
        .map((key) => fas[key].iconName)
    );
  };

  useEffect(() => {
    searchIcons(iconSearchText);
  }, [iconSearchText]);

  const getRoles = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyRoles(company);
      if (!result.message) setRoles(result);
    }
  };

  const getPermissions = async () => {
    const result = await es.getPermissions();
    setPermissions(result);
  };

  const getMembers = async () => {
    setLoading(true);
    const result = await es.getMembers(
      company,
      pagination,
      searchParameters,
      "role"
    );
    if (result != null) {
      setMembers(result.data);
      setTotalPages(result.last_page);
    }
    setLoading(false);
  };

  const togglePermission = (permission, added) => {
    var perms = [];

    if (currentRole.permissions) perms = currentRole.permissions;

    if (added) {
      perms.push(permission);
    } else {
      perms = perms.filter((m) => m.id != permission.id);
    }

    setCurrentRole({ ...currentRole, permissions: perms });
  };

  const saveRole = async () => {
    setButtonIsLoading(true);
    var result = null;

    if (currentRole.id) {
      result = await es.updateRole(company, currentRole);
    } else {
      result = await es.createRole(company, currentRole);
    }

    if (result.success) {
      toast.success(container.localeManager.strings.roleSaved);
      setCurrentRole(null);
      getRoles();
    } else {
      toast.error(result.message);
    }
    setButtonIsLoading(false);
  };

  const getColoredSpan = (member, value) => {
    if (member.role != null) {
      return <span style={{ color: member.role.color }}>{value}</span>;
    } else {
      return <span>{value}</span>;
    }
  };

  const getRoleColoredSpan = (role) => {
    return <span style={{ color: role.color }}>{role.name}</span>;
  };

  const removeMember = async (user) => {
    if (
      await confirmWrapper(container.localeManager.strings.kickMemberConf, {
        container: container,
      })
    ) {
      const result = await es.kickMember(company, user);
      if (result.success) {
        getMembers();
      } else {
        toast.error(result.message);
      }
    }
  };

  const deleteRole = async (role) => {
    if (
      await confirmWrapper(container.localeManager.strings.deleteRoleConf, {
        container: container,
      })
    ) {
      const result = await es.deleteRole(company, role);
      if (result.success) {
        getRoles();
        getMembers();
      } else {
        toast.error(result.message);
      }
    }
  };

  const canBeKicked = (member) => {
    if (member.role != null && member.role.owner) return false;

    return true;
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const changeSortingField = ({ field, direction }) => {
    setSearchParameters({
      ...searchParameters,
      sortingField: field,
      sortingDirection: direction,
    });
  };

  const syncMembers = async () => {
    setLoading(true);
    const result = await es.syncMembers(globalContext.company);
    if (result.success) {
      toast.success(
        container.localeManager.formatString(
          container.localeManager.strings.synchedXMembers,
          [result.newMembers]
        ),
        { position: "top-right" }
      );
    }
    if (result.newMembers > 0) {
      await getMembers();
    }
    setLoading(false);
  };

  const getContextualMenuItems = (user) => {
    const items = [];

    let canChangeRole = true;

    if (user.role != null && user.role.owner) canChangeRole = false;

    items.push({
      title: container.localeManager.strings.details,
      click: () => navigate(`/user/${user.id}`),
      icon: <FontAwesomeIcon icon="eye"></FontAwesomeIcon>,
    });

    if (es.can(member, "members.manage_roles")) {
      items.push({
        title: container.localeManager.strings.roleAndTags,
        click: () => setUserChangeRole(user),
        icon: <FontAwesomeIcon icon="user-shield"></FontAwesomeIcon>,
      });
    }

    if (es.can(member, "members.kick") && canBeKicked(user)) {
      items.push({
        title: container.localeManager.strings.kickMember,
        click: () => removeMember(user),
        icon: <FontAwesomeIcon icon="user-slash"></FontAwesomeIcon>,
      });
    }

    return items;
  };

  const saveUser = async () => {
    const result = await es.changeRole(company.id, userChangeRole.id, newRole);
    if (result.success) {
      setUserChangeRole(null);
      setNewRole(null);
      await getMembers();
    } else {
      toast.error(result.message);
    }
  };

  const getTags = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyTags(company.id);

      if (!result.message) setTags(result);
    }
  };

  const saveTag = async () => {
    let result;

    if (!currentTag.id) {
      result = await es.createCompanyTag(company.id, currentTag);
    } else {
      result = await es.updateCompanyTag(company.id, currentTag);
    }

    if (result.success) {
      toast.success(container.localeManager.strings.tagSaved);
      setCurrentTag({ name: "", icon: null, color: null });
      getTags();
    } else {
      toast.error(result.message);
    }
  };

  const deleteTag = async (tag) => {
    const result = await es.deleteCompanyTag(company.id, tag.id);
    if (result.success) {
      toast.success(container.localeManager.strings.tagDeleted);
      getTags();
    } else {
      toast.error(result.message);
    }
  };

  const associateTags = async () => {
    const result = await es.associateCompanyTagsToUser(
      company.id,
      userChangeRole.id,
      userChangeRole.company_tags
    );
    if (result.success) {
      toast.success(container.localeManager.strings.dataUpdated);
      getMembers();
    } else {
      toast.error(result.message);
    }
  };

  const toggleColumn = (checked, field) => {
    if (checked) {
      setColumns([...columns, field]);
    } else {
      setColumns(columns.filter((m) => m != field));
    }
  };

  const addTag = (id) => {
    if (id) {
      if (!userChangeRole.company_tags.find((m) => m.id == id)) {
        const newTags = [...userChangeRole.company_tags];
        newTags.push(tags.find((m) => m.id == id));
        setUserChangeRole({ ...userChangeRole, company_tags: newTags });
      }
    }
  };

  const showSalary = () => {
    if (company.company_type == "realistic" || company.company_type == "both") {
      if (
        globalContext.member &&
        globalContext.member.company_id == company.id &&
        (es.can(globalContext.member, "company.*") ||
          es.can(globalContext.member, "members.*"))
      )
        return true;
    }

    return false;
  };

  const handleRowsPerPageChange = (perPage) => {
    setPagination({ ...pagination, page: 1, perPage: perPage });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        <VTCMenu active={"/users"} container={container}></VTCMenu>

        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <h5 className="d-inline-block w-100 mb-3">
                    {container.localeManager.strings.members}
                    <div className="float-end mt-3 mt-md-0">
                      {es.can(member, "members.manage_roles") && (
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() => toggleRoleModal(true)}
                        >
                          <FontAwesomeIcon icon="users-gear"></FontAwesomeIcon>
                          {container.localeManager.strings.manageRoles}
                        </Button>
                      )}
                      {es.can(member, "members.manage_roles") && (
                        <Button
                          size="sm"
                          className="ms-2"
                          color="primary"
                          onClick={() => toggleTagsModal(true)}
                        >
                          <FontAwesomeIcon icon="user-tag"></FontAwesomeIcon>
                          {container.localeManager.strings.tags}
                        </Button>
                      )}
                      {es.can(member, "members.manage_applications") && (
                        <Button
                          size="sm"
                          className="ms-0 ms-md-2 mt-3 mt-md-0"
                          color="primary"
                          onClick={() => navigate("/applications")}
                        >
                          <FontAwesomeIcon icon="tasks"></FontAwesomeIcon>
                          {container.localeManager.strings.manageApplications}
                        </Button>
                      )}
                    </div>
                  </h5>
                  <FormGroup row>
                    <Col>
                      <Label>{container.localeManager.strings.driver}</Label>
                      <Input
                        type="text"
                        minLength={3}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            name: e.target.value,
                          })
                        }
                        value={searchParameters.name}
                      ></Input>
                    </Col>
                    <Col>
                      <Label>
                        {container.localeManager.strings.roleNoPoints}
                      </Label>
                      <Input
                        type="select"
                        value={searchParameters.role_id}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            role_id: e.target.value,
                          })
                        }
                      >
                        <option value="">
                          {container.localeManager.strings.all}
                        </option>
                        {roles.map((m) => {
                          return <option value={m.id}>{m.name}</option>;
                        })}
                      </Input>
                    </Col>
                    <Col
                      sm="auto"
                      className="d-flex align-items-end mt-3 mt-md-0"
                    >
                      <Button size="sm" onClick={getMembers} color="primary">
                        <FontAwesomeIcon icon="search"></FontAwesomeIcon>
                        {container.localeManager.strings.search}
                      </Button>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4}>
                      <Label>
                        {container.localeManager.strings.searchBySteamID}
                      </Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            steam_id: e.target.value,
                          })
                        }
                        value={searchParameters.steam_id}
                      ></Input>
                    </Col>
                    <Col sm={4}>
                      <Label>
                        {container.localeManager.strings.searchBySteamUsername}
                      </Label>
                      <Input
                        type="text"
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            steam_username: e.target.value,
                          })
                        }
                        value={searchParameters.steam_username}
                      ></Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4}>
                      <label class="form-check form-switch form-check-custom form-check-solid">
                        <input
                          onChange={(e) =>
                            setSearchParameters({
                              ...searchParameters,
                              inactive: e.target.checked,
                            })
                          }
                          class="form-check-input"
                          type="checkbox"
                          checked={searchParameters.inactive}
                        />
                        <span class="form-check-label">
                          {container.localeManager.strings.inactive}
                        </span>
                      </label>
                    </Col>
                  </FormGroup>
                  {!loading && (
                    <>
                      {totalPages > 1 && (
                        <div className="mb-3">
                          <Paginator
                            showRowsPerPage={true}
                            handleRowsPerPageChange={handleRowsPerPageChange}
                            forcePage={pagination.page - 1}
                            totalPages={totalPages}
                            handlePageClick={handlePageClick}
                          />
                        </div>
                      )}
                      <Table
                        responsive
                        striped
                        size="sm"
                        className="table-row-dashed table-row-gray-300 align-middle gs-0"
                        hover
                      >
                        <thead>
                          <tr className="fw-bolder text-gray-700 bg-light">
                            {columns.includes("id") && (
                              <th scope="col" className="ps-4">
                                #
                              </th>
                            )}
                            {columns.includes("member") && (
                              <th scope="col">
                                {container.localeManager.strings.name}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="name"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("steam") && (
                              <th scope="col">Steam</th>
                            )}
                            {columns.includes("role") && (
                              <th scope="col">
                                {container.localeManager.strings.roleNoPoints}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="roles.order"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("revenue") && (
                              <th scope="col">
                                {container.localeManager.strings.revenue}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="total_revenue"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("distance") && (
                              <th scope="col">
                                {container.localeManager.strings.totDistance}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="total_driven_distance_km"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("mass") && (
                              <th scope="col">
                                {container.localeManager.strings.totMass}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="total_cargo_mass"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("points") && (
                              <th scope="col">
                                {container.localeManager.strings.points}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="points"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("level") && (
                              <th scope="col">
                                {container.localeManager.strings.level}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="level"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {columns.includes("last_job_days") && (
                              <th scope="col">
                                {container.localeManager.strings.last_job_days}
                                <OrderByButtons
                                  container={container}
                                  currentSortingDirection={
                                    searchParameters.sortingDirection
                                  }
                                  currentSortingField={
                                    searchParameters.sortingField
                                  }
                                  field="last_job_days"
                                  onClick={changeSortingField}
                                ></OrderByButtons>
                              </th>
                            )}
                            {showSalary() &&
                              columns.includes("total_earned") && (
                                <th scope="col">
                                  {container.localeManager.strings.total_earned}
                                  <OrderByButtons
                                    container={container}
                                    currentSortingDirection={
                                      searchParameters.sortingDirection
                                    }
                                    currentSortingField={
                                      searchParameters.sortingField
                                    }
                                    field="total_earned"
                                    onClick={changeSortingField}
                                  ></OrderByButtons>
                                </th>
                              )}
                            {columns.includes("roleSalary") && (
                              <th scope="col">
                                {container.localeManager.strings.roleSalary}
                              </th>
                            )}
                            {columns.includes("tags") && (
                              <th scope="col">
                                {container.localeManager.strings.tags}
                              </th>
                            )}
                            <th scope="col" className="text-end pe-3">
                              <ButtonDropdownMenu
                                buttonContent={
                                  <FontAwesomeIcon
                                    icon="table-columns"
                                    className="m-0"
                                  ></FontAwesomeIcon>
                                }
                                menuTitle={
                                  container.localeManager.strings.columns
                                }
                                menuId="jobs_table_columns_selector"
                                menuContent={
                                  <div className="p-3">
                                    {availableColumns.map((m) => {
                                      return (
                                        <div className="mt-2">
                                          <div class="form-check form-check-sm form-check-custom form-check-solid">
                                            <input
                                              checked={columns.includes(m)}
                                              class="form-check-input me-1"
                                              type="checkbox"
                                              onChange={(e) =>
                                                toggleColumn(
                                                  e.target.checked,
                                                  m
                                                )
                                              }
                                            />{" "}
                                            {container.localeManager.strings[m]}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                }
                                buttonClassNames="btn-text p-0"
                                buttonColor="transparent"
                                title="Columns"
                              ></ButtonDropdownMenu>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {members.map((m) => {
                            return (
                              <tr>
                                {columns.includes("id") && (
                                  <td className="ps-1">{m.id}</td>
                                )}
                                {columns.includes("member") && (
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <Link to={`/user/${m.id}`}>
                                        <img
                                          src={m.avatar_url}
                                          className="avatar h-30px rounded me-3"
                                        />
                                        {getColoredSpan(m, m.name)}
                                      </Link>
                                    </div>
                                  </td>
                                )}
                                {columns.includes("steam") && (
                                  <td>
                                    {m.steam != null && (
                                      <a
                                        onClick={() =>
                                          truckyService.openUrlInDefaultBrowser(
                                            `https://steamcommunity.com/profiles/${m.steam.external_user_id}`
                                          )
                                        }
                                      >
                                        {m.steam.external_username}
                                      </a>
                                    )}
                                  </td>
                                )}
                                {columns.includes("role") && (
                                  <td>
                                    {m.role != null
                                      ? getColoredSpan(m, m.role.name)
                                      : ""}
                                  </td>
                                )}
                                {columns.includes("revenue") && (
                                  <td>
                                    {m.total_revenue != null
                                      ? container.localeManager
                                          .numeral(m.total_revenue)
                                          .format("0,0")
                                      : 0}{" "}
                                    {company.currency}
                                  </td>
                                )}
                                {columns.includes("distance") && (
                                  <td>
                                    {m.total_driven_distance_km != null
                                      ? UserDefinedUnits.convertDistance(
                                          member,
                                          null,
                                          m.total_driven_distance_km
                                        )
                                      : 0}
                                  </td>
                                )}
                                {columns.includes("mass") && (
                                  <td>
                                    {m.total_cargo_mass_t != null
                                      ? UserDefinedUnits.convertWeight(
                                          member,
                                          null,
                                          m.total_cargo_mass_t
                                        )
                                      : 0}
                                  </td>
                                )}
                                {columns.includes("points") && (
                                  <td>
                                    {container.localeManager
                                      .numeral(m.points)
                                      .format()}
                                  </td>
                                )}
                                {columns.includes("level") && (
                                  <td>
                                    {container.localeManager
                                      .numeral(m.level)
                                      .format()}
                                  </td>
                                )}
                                {columns.includes("last_job_days") && (
                                  <td>
                                    {m.last_job_days != null && (
                                      <span>
                                        {container.localeManager
                                          .numeral(m.last_job_days)
                                          .format()}{" "}
                                        {container.localeManager.strings.days}
                                      </span>
                                    )}
                                  </td>
                                )}
                                {showSalary() &&
                                  columns.includes("total_earned") && (
                                    <td>
                                      {container.localeManager
                                        .numeral(m.total_earned)
                                        .format()}{" "}
                                      T¢
                                    </td>
                                  )}
                                {columns.includes("roleSalary") && (
                                  <td>
                                    {m.role?.additional_member_salary != null &&
                                      m.role?.additional_member_salary > 0 && (
                                        <span>
                                          {m.role?.additional_member_salary}
                                        </span>
                                      )}
                                  </td>
                                )}
                                {columns.includes("tags") && (
                                  <td>
                                    {m.company_tags != null &&
                                      m.company_tags.length > 0 && (
                                        <>
                                          {m.company_tags.map((m) => {
                                            return (
                                              <CustomBadge
                                                light={true}
                                                size="sm"
                                                className="me-3 mb-2"
                                                style={
                                                  m.color
                                                    ? {
                                                        backgroundColor:
                                                          m.color,
                                                      }
                                                    : null
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={m.icon}
                                                ></FontAwesomeIcon>
                                                {m.name}
                                              </CustomBadge>
                                            );
                                          })}
                                        </>
                                      )}
                                  </td>
                                )}
                                <td className="text-end">
                                  <ButtonDropdownMenu
                                    buttonClassNames="btn-icon w-30px h-30px ms-auto"
                                    buttonColor="active-light-primary"
                                    buttonContent={
                                      <FontAwesomeIcon
                                        icon="ellipsis-v"
                                        className="me-0"
                                      ></FontAwesomeIcon>
                                    }
                                    menuItems={getContextualMenuItems(m)}
                                  ></ButtonDropdownMenu>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <Paginator
                        forcePage={pagination.page - 1}
                        totalPages={totalPages}
                        handlePageClick={handlePageClick}
                      />
                    </>
                  )}
                  {showChangeRoleModal && userChangeRole != null && (
                    <Modal
                      size="lg"
                      className="big-modal"
                      wrapClassName="big-modal-wrap"
                      modalClassName="big-modal-container"
                      contentClassName="big-modal-content"
                      isOpen={showChangeRoleModal}
                      toggle={() => toggleChangeRoleModal(false)}
                    >
                      <ModalHeader
                        toggle={() => toggleChangeRoleModal(false)}
                        close={
                          <Button
                            color="active-light-primary"
                            className="btn-icon btn-sm w-30px h-30px ms-auto"
                            onClick={() => toggleChangeRoleModal(false)}
                          >
                            <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                          </Button>
                        }
                      >
                        {container.localeManager.strings.roleAndTags}
                      </ModalHeader>
                      <ModalBody>
                        {(userChangeRole.role == null ||
                          (userChangeRole.role != null &&
                            !userChangeRole.role.owner)) && (
                          <div className="mb-5">
                            <InputGroup>
                              <Input
                                value={newRole}
                                onChange={(e) => setNewRole(e.target.value)}
                                type="select"
                              >
                                <option value="">
                                  {
                                    container.localeManager.strings
                                      .noRoleAssigned
                                  }
                                </option>
                                {roles
                                  .filter((r) => !r.owner)
                                  .map((r) => {
                                    return (
                                      <option value={r.id}>{r.name}</option>
                                    );
                                  })}
                              </Input>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={saveUser}
                              >
                                {container.localeManager.strings.save}
                              </Button>
                            </InputGroup>
                          </div>
                        )}
                        <FormGroup>
                          <Label>{container.localeManager.strings.tags}</Label>
                          <Input
                            type="select"
                            onChange={(e) => addTag(e.target.value)}
                          >
                            <option></option>
                            {tags.map((m) => {
                              return <option value={m.id}>{m.name}</option>;
                            })}
                          </Input>
                          <div className="mt-3">
                            {userChangeRole.company_tags.map((m) => {
                              return (
                                <a
                                  onClick={() => {
                                    setUserChangeRole({
                                      ...userChangeRole,
                                      company_tags:
                                        userChangeRole.company_tags.filter(
                                          (x) => x.id != m.id
                                        ),
                                    });
                                  }}
                                  className="badge badge-secondary me-3 mb-3"
                                >
                                  {m.name}{" "}
                                  <FontAwesomeIcon
                                    icon="times"
                                    className="me-0 ms-3"
                                  ></FontAwesomeIcon>
                                </a>
                              );
                            })}
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={associateTags}
                          >
                            {container.localeManager.strings.save}
                          </Button>
                        </FormGroup>
                      </ModalBody>
                    </Modal>
                  )}
                  {showRolesModal && (
                    <Modal
                      size="lg"
                      className="big-modal"
                      wrapClassName="big-modal-wrap"
                      modalClassName="big-modal-container"
                      contentClassName="big-modal-content"
                      isOpen={showRolesModal}
                      toggle={() => toggleRoleModal(false)}
                    >
                      <ModalHeader
                        toggle={() => toggleRoleModal(false)}
                        close={
                          <Button
                            color="active-light-primary"
                            className="btn-icon btn-sm w-30px h-30px ms-auto"
                            onClick={() => toggleRoleModal(false)}
                          >
                            <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                          </Button>
                        }
                      >
                        {container.localeManager.strings.rolesManagement}
                      </ModalHeader>
                      <ModalBody>
                        {currentRole == null && (
                          <div className="text-end mb-3">
                            <Button
                              onClick={() => setCurrentRole({})}
                              size="sm"
                              color="primary"
                            >
                              <FontAwesomeIcon icon="plus" />
                              {container.localeManager.strings.addRole}
                            </Button>
                          </div>
                        )}
                        {currentRole == null && roles.length > 0 && (
                          <Table
                            responsive
                            striped
                            size="sm"
                            className="table-row-dashed table-row-gray-300 align-middle gs-0"
                            hover
                          >
                            <thead>
                              <tr className="fw-bolder text-gray-700 bg-light">
                                <th scope="col" className="ps-4">
                                  {container.localeManager.strings.name}
                                </th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {roles.map((m) => {
                                return (
                                  <tr>
                                    <td className="ps-2">
                                      {getRoleColoredSpan(m)}
                                    </td>
                                    <td className="text-end">
                                      <Button
                                        title={
                                          container.localeManager.strings.edit
                                        }
                                        onClick={() => setCurrentRole(m)}
                                        className="btn-icon w-30px h-30px ms-auto"
                                        size="sm"
                                        color="active-light-primary"
                                      >
                                        <FontAwesomeIcon
                                          icon="pencil-alt"
                                          className="me-0"
                                        ></FontAwesomeIcon>
                                      </Button>
                                      <Button
                                        title={
                                          container.localeManager.strings.delete
                                        }
                                        disabled={m.owner}
                                        className="btn-icon w-30px h-30px ms-auto"
                                        onClick={() => deleteRole(m)}
                                        size="sm"
                                        color="active-light-danger"
                                      >
                                        <FontAwesomeIcon
                                          icon="trash-alt"
                                          className="me-0"
                                        ></FontAwesomeIcon>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        )}
                        {currentRole != null && (
                          <div>
                            <div className="mb-3">
                              <Button
                                onClick={() => setCurrentRole(null)}
                                size="sm"
                                color="secondary"
                              >
                                <FontAwesomeIcon icon="chevron-left" />
                                {container.localeManager.strings.back}
                              </Button>
                            </div>
                            <FormGroup row>
                              <Col>
                                <Label>
                                  {container.localeManager.strings.name}
                                </Label>
                                <Input
                                  type="text"
                                  placeholder="Role Name"
                                  value={currentRole.name}
                                  onChange={(e) =>
                                    setCurrentRole({
                                      ...currentRole,
                                      name: e.target.value,
                                    })
                                  }
                                ></Input>
                              </Col>
                              <Col>
                                <Label>
                                  {container.localeManager.strings.order}
                                </Label>
                                <Input
                                  type="number"
                                  min="0"
                                  placeholder="Order"
                                  value={currentRole.order}
                                  onChange={(e) =>
                                    setCurrentRole({
                                      ...currentRole,
                                      order: e.target.value,
                                    })
                                  }
                                ></Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col>
                                <Label>
                                  {container.localeManager.strings.color}
                                </Label>
                                <Input
                                  type="color"
                                  placeholder={
                                    container.localeManager.strings.roleColor
                                  }
                                  value={currentRole.color}
                                  onChange={(e) =>
                                    setCurrentRole({
                                      ...currentRole,
                                      color: e.target.value,
                                    })
                                  }
                                ></Input>
                              </Col>
                              <Col>
                                {(company.company_type == "realistic" ||
                                  company.company_type == "both") && (
                                  <FormGroup>
                                    <Label>
                                      {
                                        container.localeManager.strings
                                          .roleSalary
                                      }
                                    </Label>
                                    <InputGroup>
                                      <Input
                                        type="number"
                                        min="0"
                                        step="any"
                                        value={
                                          currentRole.additional_member_salary
                                        }
                                        onChange={(e) =>
                                          setCurrentRole({
                                            ...currentRole,
                                            additional_member_salary:
                                              e.target.value,
                                          })
                                        }
                                      ></Input>
                                      <InputGroupText>
                                        {company.salary_type ==
                                        "revenue_percentage"
                                          ? "%"
                                          : "T¢"}
                                      </InputGroupText>
                                    </InputGroup>
                                    <FormText color="muted">
                                      {
                                        container.localeManager.strings
                                          .roleSalaryDesc
                                      }
                                      <br />
                                      {
                                        container.localeManager.strings
                                          .totalSalaryPerRole
                                      }{" "}
                                      {parseFloat(company.base_member_salary) +
                                        parseFloat(
                                          currentRole.additional_member_salary
                                        )}{" "}
                                      {company.salary_type ==
                                      "revenue_percentage"
                                        ? "%"
                                        : "T¢"}
                                    </FormText>
                                  </FormGroup>
                                )}
                              </Col>
                            </FormGroup>
                            {config.ENABLE_DISCORD_INTEGRATION &&
                              discordRoles &&
                              discordRoles.bot_in_server &&
                              discordRoles.roles && (
                                <FormGroup row>
                                  <Col>
                                    <Label>Discord Role</Label>
                                    <Input
                                      type="select"
                                      value={currentRole.discord_role_id}
                                      onChange={(e) =>
                                        setCurrentRole({
                                          ...currentRole,
                                          discord_role_id: e.target.value,
                                        })
                                      }
                                    >
                                      <option value={null}></option>
                                      {discordRoles.roles.map((m) => {
                                        return (
                                          <option value={m.id}>{m.name}</option>
                                        );
                                      })}
                                    </Input>
                                  </Col>
                                  <Col></Col>
                                </FormGroup>
                              )}
                            {!currentRole.owner && (
                              <>
                                <FormGroup row>
                                  <Col>
                                    <label class="form-check form-switch form-check-custom form-check-solid">
                                      <input
                                        onChange={(e) =>
                                          setCurrentRole({
                                            ...currentRole,
                                            inactive: e.target.checked,
                                          })
                                        }
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={currentRole.inactive}
                                      />
                                      <span class="form-check-label">
                                        {
                                          container.localeManager.strings
                                            .inactive
                                        }
                                      </span>
                                    </label>
                                  </Col>
                                  <Col>
                                    <label class="form-check form-switch form-check-custom form-check-solid">
                                      <input
                                        onChange={(e) =>
                                          setCurrentRole({
                                            ...currentRole,
                                            new_member_default_role:
                                              e.target.checked,
                                          })
                                        }
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={
                                          currentRole.new_member_default_role
                                        }
                                      />
                                      <span class="form-check-label">
                                        {
                                          container.localeManager.strings
                                            .new_member_default_role
                                        }
                                      </span>
                                    </label>
                                    <div className="form-text text-muted">
                                      {
                                        container.localeManager.strings
                                          .new_member_default_role_hint
                                      }
                                    </div>
                                  </Col>
                                </FormGroup>
                              </>
                            )}
                            {!currentRole.owner && (
                              <div className="mt-3 grid cols-2">
                                <h5>
                                  {container.localeManager.strings.permissions}
                                </h5>
                                {permissions.map((m) => {
                                  return (
                                    <FormGroup>
                                      <label class="form-check form-switch form-check-custom form-check-solid">
                                        <input
                                          onChange={(e) =>
                                            togglePermission(
                                              m,
                                              e.currentTarget.checked
                                            )
                                          }
                                          class="form-check-input"
                                          type="checkbox"
                                          checked={
                                            currentRole.permissions
                                              ? currentRole.permissions.find(
                                                  (p) => p.id == m.id
                                                ) != null
                                              : false
                                          }
                                        />
                                        <span class="form-check-label">
                                          {m.name}
                                        </span>
                                      </label>
                                    </FormGroup>
                                  );
                                })}
                              </div>
                            )}
                            <FormGroup className="mt-3">
                              <LoadingButton
                                size="sm"
                                loading={buttonIsLoading}
                                color="primary"
                                onClick={() => saveRole()}
                              >
                                {container.localeManager.strings.save}
                              </LoadingButton>
                            </FormGroup>
                          </div>
                        )}
                      </ModalBody>
                    </Modal>
                  )}
                  {showTagsModal && (
                    <Modal
                      size="lg"
                      className="big-modal"
                      wrapClassName="big-modal-wrap"
                      modalClassName="big-modal-container"
                      contentClassName="big-modal-content"
                      isOpen={showTagsModal}
                      toggle={() => toggleTagsModal(false)}
                    >
                      <ModalHeader
                        toggle={() => toggleTagsModal(false)}
                        close={
                          <Button
                            color="active-light-primary"
                            className="btn-icon btn-sm w-30px h-30px ms-auto"
                            onClick={() => toggleTagsModal(false)}
                          >
                            <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                          </Button>
                        }
                      >
                        {container.localeManager.strings.tags}
                      </ModalHeader>
                      <ModalBody>
                        <FormGroup>
                          <Label>{container.localeManager.strings.name}</Label>
                          <Input
                            value={currentTag.name}
                            type="text"
                            onChange={(e) =>
                              setCurrentTag({
                                ...currentTag,
                                name: e.target.value,
                              })
                            }
                          ></Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>{container.localeManager.strings.color}</Label>
                          <Input
                            value={currentTag.color}
                            type="color"
                            onChange={(e) =>
                              setCurrentTag({
                                ...currentTag,
                                color: e.target.value,
                              })
                            }
                          ></Input>
                        </FormGroup>
                        {config.ENABLE_DISCORD_INTEGRATION &&
                          discordRoles &&
                          discordRoles.bot_in_server &&
                          discordRoles.roles && (
                            <FormGroup row>
                              <Col>
                                <Label>Discord Role</Label>
                                <Input
                                  type="select"
                                  value={currentTag.discord_role_id}
                                  onChange={(e) =>
                                    setCurrentTag({
                                      ...currentTag,
                                      discord_role_id: e.target.value,
                                    })
                                  }
                                >
                                  <option value={null}></option>
                                  {discordRoles.roles.map((m) => {
                                    return (
                                      <option value={m.id}>{m.name}</option>
                                    );
                                  })}
                                </Input>
                              </Col>
                              <Col></Col>
                            </FormGroup>
                          )}
                        <FormGroup>
                          <Label>{container.localeManager.strings.icon}</Label>
                          {currentTag.icon != null && (
                            <div className="mt-3">
                              <FontAwesomeIcon
                                size="3x"
                                icon={currentTag.icon}
                              ></FontAwesomeIcon>
                            </div>
                          )}
                          <div className="mt-2">
                            <Input
                              placeholder={
                                container.localeManager.strings.browseIcons
                              }
                              value={iconSearchText}
                              onChange={(e) =>
                                setIconSearchText(e.target.value)
                              }
                            ></Input>
                            <div className="mt-3">
                              {icons.map((icon) => {
                                return (
                                  <span className="border rounded p-2 me-2 mb-2 d-inline-block">
                                    <FontAwesomeIcon
                                      className="me-0"
                                      size="2x"
                                      onClick={() =>
                                        setCurrentTag({
                                          ...currentTag,
                                          icon: icon,
                                        })
                                      }
                                      icon={icon}
                                    ></FontAwesomeIcon>
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="mt-3">
                          <Button color="primary" size="sm" onClick={saveTag}>
                            {container.localeManager.strings.save}
                          </Button>
                        </FormGroup>
                        <div className="mt-3">
                          <Table
                            responsive
                            striped
                            size="sm"
                            className="table-row-dashed table-row-gray-300 align-middle gs-0"
                            hover
                          >
                            <thead>
                              <tr className="fw-bolder text-gray-700 bg-light">
                                <th className="ps-2">
                                  {container.localeManager.strings.name}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            {tags.map((m) => {
                              return (
                                <tr>
                                  <td className="py-2">
                                    <CustomBadge
                                      style={{ backgroundColor: m.color }}
                                    >
                                      {m.icon ? (
                                        <FontAwesomeIcon
                                          icon={m.icon}
                                        ></FontAwesomeIcon>
                                      ) : (
                                        ""
                                      )}
                                      {m.name}
                                    </CustomBadge>
                                  </td>
                                  <td className="text-end">
                                    <Button
                                      title={
                                        container.localeManager.strings.edit
                                      }
                                      onClick={() => setCurrentTag(m)}
                                      className="btn-icon w-30px h-30px ms-auto"
                                      size="sm"
                                      color="active-light-primary"
                                    >
                                      <FontAwesomeIcon
                                        icon="pencil-alt"
                                        className="me-0"
                                      ></FontAwesomeIcon>
                                    </Button>
                                    <Button
                                      title={
                                        container.localeManager.strings.delete
                                      }
                                      className="btn-icon w-30px h-30px ms-auto"
                                      onClick={() => deleteTag(m)}
                                      size="sm"
                                      color="active-light-danger"
                                    >
                                      <FontAwesomeIcon
                                        icon="trash-alt"
                                        className="me-0"
                                      ></FontAwesomeIcon>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </Table>
                        </div>
                      </ModalBody>
                    </Modal>
                  )}
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              <Tips
                tipKey={tipsList.KEYS.ENTERPRISE_MEMBERS}
                localeManager={container.localeManager}
              ></Tips>
              {company.external_id != null &&
                es.can(globalContext.member, "company") && (
                  <Card>
                    <CardBody>
                      <Button
                        onClick={() => syncMembers()}
                        color="primary"
                        block
                      >
                        {
                          container.localeManager.strings
                            .syncMembersFromTruckersMP
                        }
                      </Button>
                    </CardBody>
                  </Card>
                )}
              {!window.overwolf && (
                <BoxDynamicAd container={container} className="my-3" />
              )}
            </aside>
          </Row>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default Members;
