import { useContext, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Button, Col, Row, Spinner, Card, CardBody, Navbar, Nav, NavItem, NavLink, TabContent, TabPane, ModalHeader, ModalBody, Modal } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { ToastContainer, toast } from 'react-toastify';
import CompanyStats from "./CompanyStats";
import Dashboard from "./Dashboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmWrapper } from '../ui/askConfirm'
import { EnterpriseContext } from "./EnterpriseContext";
import { useNavigate } from "react-router";
import truckyService from "../../common/services/trucky-service";
import { useSearchParams } from "react-router-dom";
import MaintenanceAlerts from "./MaintenanceAlerts";
import CompanyServers from "../hosting/CompanyServers";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import VTCMenu from "../../web/components/VTCMenu";

const EnterpriseHome = ({ container }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
    const [loading, setLoading] = useState(false);
    const enterpriseService = new EnterpriseService();
    const [firstCompanyConfiguration, setFirstCompanyConfiguration] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [companyStats, setCompanyStats] = useState(null);
    const navigate = useNavigate();

    /*useEffect(() => {
        if (searchParams.get("reloadContext") == 'true' || globalContext.member == null) {
            loadContext();
        }
    }, []);
    */

    useEffect(() => {
        truckyService.setDocumentTitle('Your Company');
    }, []);

    useEffect(() => {
        if (globalContext.company != null) {
            if (globalContext.company.company_type == null) {
                setFirstCompanyConfiguration(true);
                navigate(`/company/${globalContext.company.id}/edit`);
            }
            else {
                getCompanyStats(globalContext.company);
            }
        }
    }, [globalContext.company]);

    const getCompanyStats = async (company) => {
        const stats = await enterpriseService.getCompanyStats(company.id);
        setCompanyStats(stats);
    };

    const loadContext = async () => {
        setLoading(true);
        setLoadingMessage(container.localeManager.strings.loadingCompany);
        const _member = await enterpriseService.me();
        var _company = null;

        if (_member.company_id != null) {
            _company = await enterpriseService.getCompany(_member.company_id);
        }

        setGlobalContext({ member: _member, company: _company });

        setLoadingMessage('');
        setLoading(false);
    }

    const canLeaveTheCompany = () => {
        if (globalContext.member.role == null)
            return true;

        if (globalContext.member.role != null && globalContext.member.role.owner)
            return false;

        return true;
    }

    const leaveCompany = async () => {
        if (await confirmWrapper(container.localeManager.strings.leftCompanyConf, { container: container })) {
            setLoading(true);
            await enterpriseService.leaveCompany();
            await loadContext();
            setLoading(false);
            navigate('/my/profile');
        }
    };

    return (
        <LoadingOverlay
            active={loading}
            text={<div className="text-center mt-3">{loadingMessage}</div>}
            spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
        >
             
            <Row noGutters className="" id="enterpriseContainer">
                <VTCMenu active={'/vtchub'} container={container}></VTCMenu>
                <Col>
                    <Row noGutters>
                        <Col>
                            {!firstCompanyConfiguration && globalContext.company != null &&
                                <Card className="h-100">
                                    <CardBody>
                                        <div class="hero-image" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${globalContext.company.cover_url}"` }}>
                                            <div class="hero-text">
                                                <img src={globalContext.company.avatar_url} className="avatar rounded h-100px mb-2" />
                                                <h1>{globalContext.company.name}</h1>
                                                <p>{globalContext.company.slogan}</p>
                                            </div>
                                            <div class="buttons mx-3">
                                                <Col sm="auto">
                                                    {window.overwolf &&
                                                        <Button size="sm" className="me-1" onClick={() => truckyService.openUrlInDefaultBrowser(globalContext.company.public_url)} color="secondary">{container.localeManager.strings.publicPage}</Button>
                                                    }
                                                    {enterpriseService.can(globalContext.member, 'company.*') && window.overwolf &&
                                                        <Button size="sm" onClick={() => navigate(`/company/${globalContext.company.id}/edit`)} color="secondary"><FontAwesomeIcon icon="cog" />{container.localeManager.strings.companySettings}</Button>
                                                    }
                                                    {canLeaveTheCompany() &&
                                                        <Button className="mx-1" size="sm" title={container.localeManager.strings.leaveCompany} onClick={leaveCompany} color="danger"><FontAwesomeIcon className="me-0" icon="door-open"></FontAwesomeIcon></Button>
                                                    }
                                                </Col>
                                            </div>
                                        </div>
                                        {companyStats != null &&
                                            <Dashboard container={container} stats={companyStats}></Dashboard>
                                        }
                                    </CardBody>
                                </Card>
                            }
                        </Col>
                        <aside className="ad-column-placeholder">
                            {globalContext.company != null && companyStats != null &&
                                <CompanyStats company={globalContext.company} stats={companyStats} container={container}></CompanyStats>
                            }
                            {(globalContext.company != null && (globalContext.company.company_type == 'realistic' || globalContext.company.company_type == 'both')) &&
                                <MaintenanceAlerts company={globalContext.company} container={container} />
                            }
                            {globalContext.company != null &&
                                <CompanyServers container={container} company_id={globalContext.company.id}></CompanyServers>
                            }
                             {!window.overwolf &&
                        <BoxDynamicAd container={container} className="my-3" />
                    }
                        </aside>
                    </Row>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Col>
            </Row>
        </LoadingOverlay >
    )
}

export default EnterpriseHome;