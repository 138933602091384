var dev = {
  ENV: "development",
  FEATURES_VERSION: "Development",
  ENABLE_TEST_AD: true,
  ENABLE_LEADERBOARDS: true,
  ENABLE_REALOPS_SERVICE: false,
  ENABLE_ADS_ON_GAME_LOADING: false,
  ENABLE_NEW_DESKTOP_WINDOW: true,
  ENABLE_SAVE_EDIT_MANAGER: true,
  ENABLE_CARGO_MARKET_FOR_PATRONS: true,
  ENABLE_CARGO_MARKET_FOR_EVERYONE: true,
  ENABLE_TELEPORT_FREIGHT_MARKET: true,
  ENABLE_TELEPORT_AT_COMPANY: true,
  ENABLE_AUTO_LIGHTS: true,
  ENABLE_ADVANCED_CUSTOMIZATIONS_FOR_PATRONS: true,
  ENABLE_ADVANCED_CUSTOMIZATIONS: true,
  ENABLE_LOGO_ANIMATION: true,
  ENABLE_ADVANCED_VTRPC_CUSTOMIZATIONS: true,
  ENABLE_DESKTOP_HELPER: true,
  //REALTIME_SOCKET_URL: 'ws://localhost:5001',
  //API_URL: 'http://localhost:5000',
  REALTIME_SOCKET_URL: "wss://realtime.truckyapp.com",
  API_URL: "https://api.truckyapp.com",
  //MODHUB_API_URL: 'http://localhost:8000/api',
  MODHUB_API_URL: "https://truckymods.io/api",
  //ENTERPRISE_URL: "https://vtchub.test",
  ENTERPRISE_URL: 'https://e.truckyapp.com',
  ENABLE_MOD_MANAGER: true,
  ENABLE_INTEGRATIONS: true,
  ENABLE_TOUCH_PORTAL: true,
  ENABLE_ENTERPRISE: true,
  CHANNELS: {
    Live: "",
    Testers: "Testers",
  },
  SENTRY_URL:
    "https://42bf1aaee68e4c26992889d8e86b2388@o296662.ingest.sentry.io/6132909",
  SENTRY_RATE: 1,
  SENTRY_PROJECT_NAME: "overlay-dev",
  //BROWSER_LOGIN_URL: 'http://localhost:8000/authentication/login/steam?source=windows'
  BROWSER_LOGIN_URL:
    "https://e.truckyapp.com/authentication/login/steam?source=windows",
  ENABLE_NEW_ACHIEVEMENTS: true,
  ENABLE_TMP_BUS_ROUTES: true,
  ENABLE_SPEED_SOUND_ALERT: true,
  GET_COMPANY_PLACEMENTS_FROM_TELEMETRY: true,
  PUSHER_APP_KEY: "app-key",
  PUSHER_HOST: "hub-realtime.vtchub.test", //"hub-realtime.vtchub.test",
  PUSHER_PORT: 443,
  PUSHER_FORCE_TLS: false,
  PUSHER_AUTH_URL: `https://vtchub.test/broadcasting/auth`,
  //PUSHER_AUTH_URL: `https://e-beta.truckyapp.com/broadcasting/auth`,
  FORCE_CHANNEL_SWITCH: false,
  WS_CLIENT_DEBUG: false,
  TELEMETRY_PORT_CONFIGURATION: false,
  VTC_HUB_PROFILE_SHOW_LEVEL: true,
  ENABLE_ACHIEVEMENTS: true,
  ENABLE_SUBSCRIPTIONS: true,
  AUTH_COOKIE_DOMAIN: ".vtchub.test",
  PATREON_AUTH_URL: "https://api.truckyapp.com/patreon/webapp/auth",
  //MAP_MANAGER_URL: 'http://localhost:5000/mapobjects/mapManager.js',
  MAP_MANAGER_URL: "https://api.truckyapp.com/mapobjects/mapManager.js",
  ENABLE_PROFILE_TRACKING: true,
  ENABLE_ADS: true,
  NITRO_ADS_DEMO: true,
  ENABLE_DISCORD_INTEGRATION: true,
};

var prod = {
  ENV: "production",
  FEATURES_VERSION: process.env.FEATURES_VERSION,
  ENABLE_TEST_AD: false,
  ENABLE_LEADERBOARDS: true,
  ENABLE_REALOPS_SERVICE: false,
  ENABLE_ADS_ON_GAME_LOADING: false,
  ENABLE_NEW_DESKTOP_WINDOW: true,
  ENABLE_SAVE_EDIT_MANAGER: true,
  ENABLE_CARGO_MARKET_FOR_PATRONS: true,
  ENABLE_CARGO_MARKET_FOR_EVERYONE: true,
  ENABLE_TELEPORT_FREIGHT_MARKET: true,
  ENABLE_TELEPORT_AT_COMPANY: true,
  ENABLE_AUTO_LIGHTS: true,
  ENABLE_ADVANCED_CUSTOMIZATIONS_FOR_PATRONS: true,
  ENABLE_ADVANCED_CUSTOMIZATIONS: true,
  ENABLE_LOGO_ANIMATION: false,
  ENABLE_ADVANCED_VTRPC_CUSTOMIZATIONS: true,
  ENABLE_DESKTOP_HELPER: true,
  REALTIME_SOCKET_URL: "wss://realtime.truckyapp.com",
  API_URL: "https://api.truckyapp.com",
  MODHUB_API_URL: "https://truckymods.io/api",
  ENTERPRISE_URL: "https://e.truckyapp.com",
  ENABLE_MOD_MANAGER: true,
  ENABLE_INTEGRATIONS: true,
  ENABLE_TOUCH_PORTAL: true,
  ENABLE_ENTERPRISE: false,
  CHANNELS: {
    Live: "",
    Testers: "Testers",
  },
  SENTRY_URL:
    "https://6c31a4bca45e4cdbbe33dae83064fe9a@o296662.ingest.sentry.io/5600379",
  SENTRY_RATE: 1 / 1000,
  SENTRY_PROJECT_NAME: "overlay",
  BROWSER_LOGIN_URL:
    "httpS://e.truckyapp.com/authentication/login/steam?source=windows",
  ENABLE_NEW_ACHIEVEMENTS: false,
  ENABLE_TMP_BUS_ROUTES: false,
  ENABLE_SPEED_SOUND_ALERT: true,
  GET_COMPANY_PLACEMENTS_FROM_TELEMETRY: true,
  PUSHER_APP_KEY: "79a7142e-604c-4053-8882-76f5da661013",
  PUSHER_HOST:
    "hub-realtime-6e57cad2-d182-4a07-95db-d851f712d110.shardick.workers.dev",
  PUSHER_PORT: 443,
  PUSHER_FORCE_TLS: true,
  PUSHER_AUTH_URL: `https://e.truckyapp.com/broadcasting/auth`,
  FORCE_CHANNEL_SWITCH: true,
  WS_CLIENT_DEBUG: false,
  TELEMETRY_PORT_CONFIGURATION: false,
  VTC_HUB_PROFILE_SHOW_LEVEL: true,
  ENABLE_ACHIEVEMENTS: true,
  ENABLE_SUBSCRIPTIONS: true,
  AUTH_COOKIE_DOMAIN: ".truckyapp.com",
  PATREON_AUTH_URL: "https://api.truckyapp.com/patreon/webapp/auth",
  MAP_MANAGER_URL: "https://api.truckyapp.com/mapobjects/mapManager.js",
  ENABLE_PROFILE_TRACKING: true,
  ENABLE_ADS: true,
  NITRO_ADS_DEMO: false,
  ENABLE_DISCORD_INTEGRATION: true,
};

var config = prod;

if (document.location.hostname == "hub-beta.truckyapp.com") {
  prod.ENABLE_DISCORD_INTEGRATION = true;
  prod.ENTERPRISE_URL = "https://e-beta.truckyapp.com";
  prod.NITRO_ADS_DEMO = true;
  prod.PUSHER_AUTH_URL = `https://e-beta.truckyapp.com/broadcasting/auth`;
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  config = dev;
}

module.exports = config;
