import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EnterpriseContext } from "./EnterpriseContext";
import { useNavigate, useParams } from "react-router";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { toast, ToastContainer } from "react-toastify";
import MDEditor from "@uiw/react-md-editor";
import DatePicker from "react-date-picker";
import moment from "moment";
import truckyService from "../../common/services/trucky-service";
import VTCMenu from "../../web/components/VTCMenu";

const EditFeed = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const es = new EnterpriseService();
  const [feed, setFeed] = useState({
    published_at: new Date(),
    members_only: true,
    pinned: false,
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    truckyService.setDocumentTitle("Feed Management");

    if (id) getFeed();
  }, []);

  const getFeed = async () => {
    setLoading(true);
    const result = await es.getFeedDetail(globalContext.company.id, id);
    setFeed(result);
    setLoading(false);
  };

  const save = async () => {
    setLoading(true);

    let result;

    if (!feed.id) {
      result = await es.createFeed(globalContext.company.id, feed);
    } else {
      result = await es.updateFeed(globalContext.company.id, feed);
    }

    if (result.success) {
      setFeed(result.feed);
      toast.success(container.localeManager.strings.articleSaved);
    } else {
      toast.error(result.message);
    }

    setLoading(false);
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        <Row noGutters>
          <VTCMenu  active={'/feeds/manage'} container={container}></VTCMenu>
          <Col>
            <Card>
              <CardBody>
                {window.overwolf && (
                  <div className="mb-3">
                    <Button
                      size="sm"
                      onClick={() => navigate(-1)}
                      color="secondary"
                    >
                      <FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>
                      {container.localeManager.strings.back}
                    </Button>
                  </div>
                )}
                <FormGroup>
                  <Label>{container.localeManager.strings.title} *</Label>
                  <Input
                    type="text"
                    value={feed.title}
                    onChange={(e) =>
                      setFeed({ ...feed, title: e.target.value })
                    }
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <Label>{container.localeManager.strings.summary} *</Label>
                  <Input
                    type="textarea"
                    height={200}
                    value={feed.summary}
                    onChange={(e) =>
                      setFeed({ ...feed, summary: e.target.value })
                    }
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <Label>{container.localeManager.strings.content} *</Label>
                  <MDEditor
                    data-color-mode="dark"
                    height={500}
                    value={feed.content}
                    onChange={(value) => setFeed({ ...feed, content: value })}
                  />
                </FormGroup>
                <FormGroup row>
                  {/*<Col>
                                        <Label>{container.localeManager.strings.publishedAt}</Label>
                                        <div>
                                            <DatePicker locale={container.localeManager.language}
                                                minDate={new Date()}
                                                calendarIcon={<FontAwesomeIcon icon="calendar-alt"></FontAwesomeIcon>}
                                                clearIcon={<FontAwesomeIcon icon="times"></FontAwesomeIcon>} onChange={(value) => setFeed({ ...feed, published_at: moment(value).format('YYYY-MM-DD') })}
                                                value={moment(feed.published_at).toDate()} />
                                        </div>
                                    </Col>    
                                    */}
                  <Col className="d-flex align-items-center">
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          setFeed({ ...feed, members_only: e.target.checked })
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={feed.members_only}
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.membersOnly}
                      </span>
                    </label>
                  </Col>
                  <Col className="d-flex align-items-center">
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          setFeed({ ...feed, pinned: e.target.checked })
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={feed.pinned}
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.pinned}
                      </span>
                    </label>
                  </Col>
                  <Col className="d-flex align-items-end">
                    <Button color="primary" onClick={save}>
                      {container.localeManager.strings.save}
                    </Button>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          {!container.dataService.data.enablePremiumFeature() && (
            <aside className="ad-column-placeholder"></aside>
          )}
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LoadingOverlay>
    </>
  );
};

export default EditFeed;
