import { useContext, useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-date-picker";
import { ToastContainer, toast } from "react-toastify";
import classNames from "classnames";
import { confirmWrapper } from "../ui/askConfirm";
import truckyService from "../../common/services/trucky-service";
import VTCMenu from "../../web/components/VTCMenu";

const EditCompanyAchievement = ({ container }) => {
  const adrClasses = [
    {
      label: container.localeManager.strings.adr1,
      value: 1,
    },
    {
      label: container.localeManager.strings.adr2,
      value: 2,
    },
    {
      label: container.localeManager.strings.adr3,
      value: 3,
    },
    {
      label: container.localeManager.strings.adr4,
      value: 4,
    },
    {
      label: container.localeManager.strings.adr6,
      value: 6,
    },
    {
      label: container.localeManager.strings.adr8,
      value: 8,
    },
  ];

  const markets = [
    {
      label: container.localeManager.strings.freight_market,
      value: "freight_market",
    },
    {
      label: container.localeManager.strings.cargo_market,
      value: "cargo_market",
    },
    {
      label: container.localeManager.strings.external_market,
      value: "external_market",
    },
    {
      label: container.localeManager.strings.external_contracts,
      value: "external_contracts",
    },
    {
      label: container.localeManager.strings.transport_system,
      value: "transport_system",
    },
  ];

  const { globalContext } = useContext(EnterpriseContext);

  const { id } = useParams();

  const [achievement, setAchievement] = useState({
    cargo_definition_id: [],
    market: [],
    brand_id: [],
    vehicle_model_id: [],
    in_game_city_id: [],
  });

  const navigate = useNavigate();
  const es = new EnterpriseService();
  const [cities, setCities] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("details");
  const [completion, setCompletion] = useState([]);
  const [completionFilter, setCompletionFilter] = useState("");
  const [filteredCompletion, setFilteredCompletion] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    truckyService.setDocumentTitle("Company Achievement");

    getTags();
    getRoles();

    if (id) {
      getAchievement();
    }
  }, []);

  useEffect(() => {
    if (achievement.brand_id && achievement.brand_id.length > 0) {
      getModels();
    }
  }, [achievement.brand_id]);

  useEffect(() => {
    if (achievement.game_id) {
      getCities();
      getCargos();
      getBrands();
      getModels();
      getCompanies();
    }
  }, [achievement.game_id]);

  useEffect(() => {
    if (currentTab == "completion" && completion.length == 0) {
      getCompletion();
    }
  }, [currentTab]);

  useEffect(() => {
    if (completionFilter && completionFilter != "" && completion.length > 0) {
      const newCompletionFiltered = completion.filter(
        (m) =>
          m.user &&
          m.user.name.toLowerCase().includes(completionFilter.toLowerCase())
      );
      setFilteredCompletion(newCompletionFiltered);
    }
    if (completionFilter == "") {
      setFilteredCompletion(completion);
    }
  }, [completionFilter]);

  const getRoles = async () => {
    const result = await es.getCompanyRoles(globalContext.company);
    setRoles(result);
  };

  const getTags = async () => {
    const result = await es.getCompanyTags(globalContext.company.id);
    setTags(result);
  };

  const getAchievement = async () => {
    setLoading(true);
    const result = await es.getCompanyAchievement(globalContext.company, id);
    setAchievement(result);
    setLoading(false);
  };

  const getBrands = async () => {
    const result = await es.getBrandsByGame(achievement.game_id);
    setBrands(result);
  };

  const getCities = async () => {
    const result = await es.getGameCities(achievement.game_id);
    setCities(result);
  };

  const getCompanies = async () => {
    const result = await es.getGameCompanies(achievement.game_id);
    setCompanies(result);
  };

  const getCargos = async () => {
    const result = await es.getCargos(achievement.game_id);
    setCargos(result);
  };

  const getModels = async () => {
    const result = await es.getModelsByGame(
      achievement.game_id,
      achievement.brand_id ? achievement.brand_id?.join(",") : ""
    );
    setVehicles(result);
  };

  const getYears = () => {
    var ret = [];

    for (
      var i = container.localeManager.moment().year();
      i <= container.localeManager.moment().year() + 20;
      i++
    ) {
      ret.push(i);
    }

    return ret;
  };

  const changeWeekStartDay = (value) => {
    let date = moment(value);
    setAchievement({
      ...achievement,
      weekDate: date.toDate(),
      week: date.week(),
      year: date.year(),
    });
  };

  const save = async () => {
    setLoading(true);

    let result;

    if (!achievement.id)
      result = await es.createCompanyAchievement(
        globalContext.company,
        achievement
      );
    else
      result = await es.updateCompanyAchievement(
        globalContext.company,
        achievement
      );

    if (result.success) {
      toast.success(container.localeManager.strings.achievementSaved);
      setAchievement(result.achievement);
    } else toast.error(result.message);

    setLoading(false);
  };

  const getDefaultValues = (array, value, keyProp) => {
    if (value && array) {
      const ret = value.map((m) =>
        array.find((x) => x[keyProp].toString() == m.toString())
      );
      return ret;
    }
    return [];
  };

  const getCompletion = async () => {
    setLoading(true);
    const result = await es.getCompanyAchievementCompletion(
      globalContext.company,
      achievement.id
    );
    setCompletion(result);
    setFilteredCompletion(result);
    setLoading(false);
  };

  const resetCompletion = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.resetCompletionWarning,
        { container: container }
      )
    ) {
      setLoading(true);
      const result = await es.resetCompanyAchievementCompletion(
        globalContext.company,
        achievement.id
      );
      setCompletion([]);
      setFilteredCompletion([]);
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        <Row noGutters>
          <VTCMenu  active={'/company/achievements'} container={container}></VTCMenu>
          <Col>
            <Card>
              <CardBody>
                <div className="mb-3">
                  <Button
                    size="sm"
                    onClick={() => navigate(-1)}
                    color="secondary"
                  >
                    <FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>
                    {container.localeManager.strings.back}
                  </Button>
                </div>
                <h5 className="d-inline-block w-100 mb-3">
                  {container.localeManager.strings.editAchievement}
                </h5>
                {achievement.id && (
                  <Nav
                    className="nav-custom nav-line-tabs nav-line-tabs-2x fs-6 fw-bold mb-3"
                    tabs
                  >
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: currentTab === "details",
                        })}
                        onClick={() => {
                          setCurrentTab("details");
                        }}
                      >
                        {container.localeManager.strings.details}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: currentTab === "completion",
                        })}
                        onClick={() => {
                          setCurrentTab("completion");
                        }}
                      >
                        {container.localeManager.strings.completion}
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}
                <TabContent activeTab={currentTab}>
                  <TabPane tabId="details">
                    <div class="fs-6 fw-bolder mb-3">
                      {container.localeManager.strings.whatIsTheGoal}
                    </div>
                    <FormGroup row>
                      <Col>
                        <Label>{container.localeManager.strings.goal} *</Label>
                        <Input
                          value={achievement.control_value}
                          type="number"
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              control_value: e.target.value,
                            })
                          }
                        ></Input>
                      </Col>
                      <Col>
                        <Label>
                          {container.localeManager.strings.goal_type} *
                        </Label>
                        <Input
                          value={achievement.control_value_type}
                          type="select"
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              control_value_type: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {container.localeManager.strings.selectOne}
                          </option>
                          <option value="jobs_count">
                            {container.localeManager.strings.jobs_count}
                          </option>
                          <option value="mass">
                            {container.localeManager.strings.mass}
                          </option>
                          <option value="distance_km">
                            {container.localeManager.strings.distance_km}
                          </option>
                          <option value="revenue">
                            {container.localeManager.strings.revenue}
                          </option>
                        </Input>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <Label>
                          {container.localeManager.strings.period} *
                        </Label>
                        <Input
                          disabled={
                            achievement.id != undefined &&
                            achievement.id != null
                          }
                          value={achievement.period}
                          type="select"
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              period: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {container.localeManager.strings.selectOne}
                          </option>
                          <option value="monthly">
                            {container.localeManager.strings.monthly}
                          </option>
                          <option value="recurring_monthly">
                            {container.localeManager.strings.recurring_monthly}
                          </option>
                          <option value="weekly">
                            {container.localeManager.strings.weekly}
                          </option>
                          <option value="recurring_weekly">
                            {container.localeManager.strings.recurring_weekly}
                          </option>
                          <option value="yearly">
                            {container.localeManager.strings.yearly}
                          </option>
                          <option value="recurring_yearly">
                            {container.localeManager.strings.recurring_yearly}
                          </option>
                          <option value="one_time">
                            {container.localeManager.strings.one_time}
                          </option>
                        </Input>
                      </Col>
                      {achievement.period == "monthly" && (
                        <Col>
                          <Label>
                            {container.localeManager.strings.month} *
                          </Label>
                          <Input
                            value={achievement.month}
                            type="select"
                            onChange={(e) =>
                              setAchievement({
                                ...achievement,
                                month: e.target.value,
                              })
                            }
                          >
                            <option value="">
                              {container.localeManager.strings.selectOne}
                            </option>
                            {container.localeManager.moment
                              .months()
                              .map((m, index) => (
                                <option value={index + 1}>{m}</option>
                              ))}
                          </Input>
                        </Col>
                      )}

                      {achievement.period == "weekly" && (
                        <Col>
                          <Label>
                            {container.localeManager.strings.week} *
                          </Label>
                          <div>
                            <DatePicker
                              locale={container.localeManager.language}
                              minDate={new Date()}
                              calendarIcon={
                                <FontAwesomeIcon icon="calendar-alt"></FontAwesomeIcon>
                              }
                              clearIcon={null}
                              onChange={changeWeekStartDay}
                              value={moment(achievement.weekDate).toDate()}
                              showWeekNumbers
                              className="w-100"
                            />
                          </div>
                        </Col>
                      )}
                      {(achievement.period == "monthly" ||
                        achievement.period == "yearly" ||
                        achievement.period == "weekly") && (
                        <Col>
                          <Label>
                            {container.localeManager.strings.year} *
                          </Label>
                          <Input
                            value={achievement.year}
                            type="select"
                            onChange={(e) =>
                              setAchievement({
                                ...achievement,
                                year: e.target.value,
                              })
                            }
                          >
                            <option value="">
                              {container.localeManager.strings.selectOne}
                            </option>
                            {getYears().map((m) => (
                              <option value={m}>{m}</option>
                            ))}
                          </Input>
                        </Col>
                      )}
                    </FormGroup>
                    <div class="fs-6 fw-bolder mb-3">
                      {container.localeManager.strings.goalHow}
                    </div>
                    <FormGroup row>
                      <Col>
                        <Label>{container.localeManager.strings.game}</Label>
                        <Input
                          value={achievement.game_id}
                          type="select"
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              game_id: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {container.localeManager.strings.all}
                          </option>
                          <option value={1}>Euro Truck Simulator 2</option>
                          <option value={2}>American Truck Simulator</option>
                        </Input>
                      </Col>
                      <Col></Col>
                    </FormGroup>
                    {achievement.game_id != null &&
                      achievement.game_id != "" && (
                        <>
                          <FormGroup row>
                            <Col>
                              <Label>
                                {container.localeManager.strings.brands}
                              </Label>
                              <Select
                                value={getDefaultValues(
                                  brands,
                                  achievement.brand_id,
                                  "in_game_id"
                                )}
                                onChange={(values) =>
                                  setAchievement({
                                    ...achievement,
                                    brand_id: values?.map((m) => m.in_game_id),
                                  })
                                }
                                classNamePrefix="react-select"
                                styles={reactSelectCustomStyles}
                                getOptionLabel={(m) => m.name}
                                getOptionValue={(m) => m.in_game_id}
                                isMulti
                                isClearable={true}
                                options={brands}
                              ></Select>
                            </Col>
                            <Col>
                              <Label>
                                {container.localeManager.strings.vehicleModels}
                              </Label>
                              <Select
                                value={getDefaultValues(
                                  vehicles,
                                  achievement.vehicle_model_id,
                                  "in_game_id"
                                )}
                                onChange={(values) =>
                                  setAchievement({
                                    ...achievement,
                                    vehicle_model_id: values?.map(
                                      (m) => m.in_game_id
                                    ),
                                  })
                                }
                                classNamePrefix="react-select"
                                styles={reactSelectCustomStyles}
                                getOptionLabel={(m) => m.full_name}
                                getOptionValue={(m) => m.in_game_id}
                                isMulti
                                isClearable={true}
                                options={vehicles}
                              ></Select>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col>
                              <Label>
                                {container.localeManager.strings.direction}
                              </Label>
                              <Input
                                value={achievement.direction}
                                type="select"
                                onChange={(e) =>
                                  setAchievement({
                                    ...achievement,
                                    direction: e.target.value,
                                  })
                                }
                              >
                                <option value="">
                                  {container.localeManager.strings.selectOne}
                                </option>
                                <option value="from">
                                  {container.localeManager.strings.from}
                                </option>
                                <option value="to">
                                  {container.localeManager.strings.to}
                                </option>
                                <option value="from-to">
                                  {container.localeManager.strings.fromOrTo}
                                </option>
                              </Input>
                            </Col>
                            <Col>
                              {achievement.direction &&
                                achievement.direction != "" && (
                                  <>
                                    <Label>
                                      {container.localeManager.strings.city}
                                    </Label>
                                    <Select
                                      value={getDefaultValues(
                                        cities,
                                        achievement.in_game_city_id,
                                        "in_game_id"
                                      )}
                                      onChange={(values) =>
                                        setAchievement({
                                          ...achievement,
                                          in_game_city_id: values?.map(
                                            (m) => m.in_game_id
                                          ),
                                        })
                                      }
                                      classNamePrefix="react-select"
                                      styles={reactSelectCustomStyles}
                                      getOptionLabel={(m) =>
                                        `${m.name} (${m.country?.name})`
                                      }
                                      getOptionValue={(m) => m.in_game_id}
                                      isMulti
                                      isClearable={true}
                                      options={cities}
                                    ></Select>
                                  </>
                                )}
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col>
                              <Label>
                                {container.localeManager.strings.source_company}
                              </Label>
                              <Select
                                value={getDefaultValues(
                                  companies,
                                  achievement.source_company_id,
                                  "in_game_id"
                                )}
                                onChange={(values) =>
                                  setAchievement({
                                    ...achievement,
                                    source_company_id: values?.map(
                                      (m) => m.in_game_id
                                    ),
                                  })
                                }
                                classNamePrefix="react-select"
                                styles={reactSelectCustomStyles}
                                getOptionLabel={(m) => m.name}
                                getOptionValue={(m) => m.in_game_id}
                                isMulti
                                isClearable={true}
                                options={companies}
                              ></Select>
                            </Col>
                            <Col>
                              <Label>
                                {
                                  container.localeManager.strings
                                    .destination_company
                                }
                              </Label>
                              <Select
                                value={getDefaultValues(
                                  companies,
                                  achievement.destination_company_id,
                                  "in_game_id"
                                )}
                                onChange={(values) =>
                                  setAchievement({
                                    ...achievement,
                                    destination_company_id: values?.map(
                                      (m) => m.in_game_id
                                    ),
                                  })
                                }
                                classNamePrefix="react-select"
                                styles={reactSelectCustomStyles}
                                getOptionLabel={(m) => m.name}
                                getOptionValue={(m) => m.in_game_id}
                                isMulti
                                isClearable={true}
                                options={companies}
                              ></Select>
                            </Col>
                          </FormGroup>
                        </>
                      )}
                    <FormGroup row>
                      <Col>
                        {achievement.game_id != null &&
                          achievement.game_id != "" && (
                            <>
                              <Label>
                                {container.localeManager.strings.cargo}
                              </Label>
                              <Select
                                value={getDefaultValues(
                                  cargos,
                                  achievement.cargo_definition_id,
                                  "id"
                                )}
                                onChange={(values) =>
                                  setAchievement({
                                    ...achievement,
                                    cargo_definition_id: values?.map(
                                      (m) => m.id
                                    ),
                                  })
                                }
                                classNamePrefix="react-select"
                                styles={reactSelectCustomStyles}
                                getOptionLabel={(m) => m.name}
                                getOptionValue={(m) => m.id}
                                isMulti
                                isClearable={true}
                                options={cargos}
                              ></Select>
                            </>
                          )}
                      </Col>
                      <Col>
                        <Label>
                          {container.localeManager.strings.cargoAdrClass}
                        </Label>
                        <Select
                          value={getDefaultValues(
                            adrClasses,
                            achievement.cargo_adr_class,
                            "value"
                          )}
                          onChange={(values) =>
                            setAchievement({
                              ...achievement,
                              cargo_adr_class: values?.map((m) => m.value),
                            })
                          }
                          classNamePrefix="react-select"
                          styles={reactSelectCustomStyles}
                          isMulti
                          isClearable={true}
                          options={adrClasses}
                        ></Select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <Label>
                          {container.localeManager.strings.cargoProperties}
                        </Label>
                        <Row>
                          <Col>
                            <label class="form-check form-switch form-check-custom form-check-solid">
                              <input
                                onChange={(e) => {
                                  setAchievement({
                                    ...achievement,
                                    cargo_is_fragile: e.target.checked,
                                  });
                                }}
                                class="form-check-input"
                                type="checkbox"
                                checked={achievement.cargo_is_fragile}
                              />
                              <span class="form-check-label">
                                {container.localeManager.strings.fragile}
                              </span>
                            </label>
                          </Col>
                          <Col>
                            <label class="form-check form-switch form-check-custom form-check-solid">
                              <input
                                onChange={(e) => {
                                  setAchievement({
                                    ...achievement,
                                    cargo_is_overweight: e.target.checked,
                                  });
                                }}
                                class="form-check-input"
                                type="checkbox"
                                checked={achievement.cargo_is_overweight}
                              />
                              <span class="form-check-label">
                                {container.localeManager.strings.overweight}
                              </span>
                            </label>
                          </Col>
                          <Col>
                            <label class="form-check form-switch form-check-custom form-check-solid">
                              <input
                                onChange={(e) => {
                                  setAchievement({
                                    ...achievement,
                                    cargo_is_valuable: e.target.checked,
                                  });
                                }}
                                class="form-check-input"
                                type="checkbox"
                                checked={achievement.cargo_is_valuable}
                              />
                              <span class="form-check-label">
                                {container.localeManager.strings.valuable}
                              </span>
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <Label>
                          {container.localeManager.strings.min_job_length_km}
                        </Label>
                        <Input
                          value={achievement.min_job_length_km}
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              min_job_length_km: e.target.value,
                            })
                          }
                        ></Input>
                      </Col>
                      <Col>
                        <Label>
                          {container.localeManager.strings.gameMode}
                        </Label>
                        <Input
                          type="select"
                          value={achievement.game_mode}
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              game_mode: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {container.localeManager.strings.all}
                          </option>
                          <option value="sp">
                            {container.localeManager.strings.singleplayer}
                          </option>
                          <option value="truckersmp">TruckersMP</option>
                        </Input>
                      </Col>
                      <Col>
                        <Label>{container.localeManager.strings.market}</Label>
                        <Select
                          value={getDefaultValues(
                            markets,
                            achievement.market,
                            "value"
                          )}
                          onChange={(values) =>
                            setAchievement({
                              ...achievement,
                              market: values?.map((m) => m.value),
                            })
                          }
                          classNamePrefix="react-select"
                          styles={reactSelectCustomStyles}
                          getOptionLabel={(m) => `${m.label}`}
                          getOptionValue={(m) => m.value}
                          isMulti
                          isClearable={true}
                          options={markets}
                        ></Select>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <Label>
                          {container.localeManager.strings.maxDamage}
                        </Label>
                        <Input
                          value={achievement.max_damage}
                          type="number"
                          min={0}
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              max_damage: e.target.value,
                            })
                          }
                        ></Input>
                      </Col>
                      <Col>
                        <Label>
                          {container.localeManager.strings.statsType}
                        </Label>
                        <Input
                          type="select"
                          value={achievement.stats_type}
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              stats_type: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {container.localeManager.strings.all}
                          </option>
                          <option value="real_miles">
                            {container.localeManager.strings.realMiles}
                          </option>
                          <option value="race_miles">
                            {container.localeManager.strings.raceMiles}
                          </option>
                        </Input>
                      </Col>
                      <Col></Col>
                      <Col></Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input
                            onChange={(e) => {
                              setAchievement({
                                ...achievement,
                                owned_trailer: e.target.checked,
                              });
                            }}
                            class="form-check-input"
                            type="checkbox"
                            checked={achievement.owned_trailer}
                          />
                          <span class="form-check-label">
                            {container.localeManager.strings.onlyOwnedTrailer}
                          </span>
                        </label>
                      </Col>
                      <Col>
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input
                            onChange={(e) => {
                              setAchievement({
                                ...achievement,
                                special_job: e.target.checked,
                              });
                            }}
                            class="form-check-input"
                            type="checkbox"
                            checked={achievement.special_job}
                          />
                          <span class="form-check-label">
                            {container.localeManager.strings.specialJob}
                          </span>
                        </label>
                      </Col>
                      <Col>
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input
                            onChange={(e) => {
                              setAchievement({
                                ...achievement,
                                is_wotr: e.target.checked,
                              });
                            }}
                            class="form-check-input"
                            type="checkbox"
                            checked={achievement.is_wotr}
                          />
                          <span class="form-check-label">
                            {container.localeManager.strings.onlyWOTRJobs}
                          </span>
                        </label>
                      </Col>
                      <Col>
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input
                            onChange={(e) => {
                              setAchievement({
                                ...achievement,
                                manual_parking: e.target.checked,
                              });
                            }}
                            class="form-check-input"
                            type="checkbox"
                            checked={achievement.manual_parking}
                          />
                          <span class="form-check-label">
                            {container.localeManager.strings.onlyManualParking}
                          </span>
                        </label>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input
                            onChange={(e) => {
                              setAchievement({
                                ...achievement,
                                with_transports: e.target.checked,
                              });
                            }}
                            class="form-check-input"
                            type="checkbox"
                            checked={achievement.with_transports}
                          />
                          <span class="form-check-label">
                            {container.localeManager.strings.withTransports}
                          </span>
                        </label>
                      </Col>
                      <Col>
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input
                            onChange={(e) => {
                              setAchievement({
                                ...achievement,
                                with_tollgates: e.target.checked,
                              });
                            }}
                            class="form-check-input"
                            type="checkbox"
                            checked={achievement.with_tollgates}
                          />
                          <span class="form-check-label">
                            {container.localeManager.strings.withTollgates}
                          </span>
                        </label>
                      </Col>
                      <Col></Col>
                      <Col></Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <label class="form-check form-switch form-check-custom form-check-solid">
                          <input
                            onChange={(e) => {
                              setAchievement({
                                ...achievement,
                                hardcore_mode: e.target.checked,
                              });
                            }}
                            class="form-check-input"
                            type="checkbox"
                            checked={achievement.hardcore_mode}
                          />
                          <span class="form-check-label">
                            {
                              container.localeManager.strings
                                .validForHardcoreLeaderboards
                            }
                          </span>
                        </label>
                      </Col>
                      <Col>
                        {achievement.hardcore_mode && (
                          <>
                            <Label>Minimum Hardcore Delivery Rating</Label>
                            <Input
                              value={achievement.min_hardcore_rating}
                              type="number"
                              min={0}
                              max={5}
                              onChange={(e) =>
                                setAchievement({
                                  ...achievement,
                                  min_hardcore_rating: e.target.value,
                                })
                              }
                            ></Input>
                          </>
                        )}
                      </Col>
                      <Col sm={8}></Col>
                    </FormGroup>
                    <div class="fs-6 fw-bolder mb-3">
                      {container.localeManager.strings.whatHappensGoal}
                    </div>
                    <FormGroup row>
                      {/*<Col>
                                                <Label>{container.localeManager.strings.assignRole}</Label>
                                                <Input type="select" value={achievement.role_id} onChange={(e) => setAchievement({ ...achievement, role_id: e.target.value })}>
                                                    <option value="">{container.localeManager.strings.selectOne}</option>
                                                    {roles.map((m) => <option value={m.id}>{m.name}</option>)}
                                                </Input>
                                            </Col>*/}
                      <Col sm={6}>
                        <Label>
                          {container.localeManager.strings.assignTag}
                        </Label>
                        <Input
                          type="select"
                          value={achievement.tag_id}
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              tag_id: e.target.value,
                            })
                          }
                        >
                          <option value="">
                            {container.localeManager.strings.selectOne}
                          </option>
                          {tags.map((m) => (
                            <option value={m.id}>{m.name}</option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>

                    <div class="fs-6 fw-bolder mb-3">
                      {container.localeManager.strings.giveItAContext}
                    </div>
                    <FormGroup row>
                      <Col>
                        <Label>{container.localeManager.strings.title}</Label>
                        <Input
                          type="text"
                          value={achievement.title}
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              title: e.target.value,
                            })
                          }
                        ></Input>
                      </Col>
                      <Col>
                        {/**
                                                <Label>Logo / Image</Label>
                                                <Input type="file" onChange={(e) => setAchievement({ ...achievement, image: e.target.files })}></Input>
                                            */}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col>
                        <Label>{container.localeManager.strings.story}</Label>
                        <Input
                          type="textarea"
                          rows={10}
                          value={achievement.story}
                          onChange={(e) =>
                            setAchievement({
                              ...achievement,
                              story: e.target.value,
                            })
                          }
                        ></Input>
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Button color="primary" size="sm" onClick={save}>
                        {container.localeManager.strings.save}
                      </Button>
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId="completion">
                    <FormGroup row>
                      <Col>
                        <Input
                          type="text"
                          onChange={(e) => setCompletionFilter(e.target.value)}
                          placeholder={
                            container.localeManager.strings
                              .searchForACompanyMember
                          }
                        ></Input>
                      </Col>
                      <Col className="text-end">
                        <Button
                          color="danger"
                          size="sm"
                          onClick={resetCompletion}
                        >
                          {container.localeManager.strings.resetCompletion}
                        </Button>
                      </Col>
                    </FormGroup>
                    <Table
                      responsive
                      striped
                      size="sm"
                      className="table-row-dashed table-row-gray-300 align-middle gs-0"
                      hover
                    >
                      <tbody>
                        {filteredCompletion.map((m) => {
                          let percentage =
                            (m.current_value / achievement.control_value) * 100;

                          if (percentage > 100) percentage = 100;

                          const pgBarColor = m.reached
                            ? "bg-success"
                            : "bg-primary";
                          return (
                            <tr>
                              <td className="ps-2" style={{ width: "20%" }}>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={m.user.avatar_url}
                                    className="avatar h-30px rounded me-3"
                                  />{" "}
                                  {m.user.name}
                                </div>
                              </td>
                              <td>
                                <div class="progress h-15px">
                                  <div
                                    class={`progress-bar h-15px ${pgBarColor}`}
                                    role="progressbar"
                                    style={{ width: `${percentage}%` }}
                                    aria-valuenow={m.current_value}
                                  >
                                    {container.localeManager
                                      .numeral(m.current_value)
                                      .format()}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <aside className="ad-column-placeholder"></aside>
        </Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </LoadingOverlay>
    </>
  );
};

export default EditCompanyAchievement;
