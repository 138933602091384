import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import Loader from "../ui/Loader";
import { confirmWrapper } from "../ui/askConfirm";
import EnterpriseService from "../../common/services/enterprise-service";
import LoadingOverlay from "react-loading-overlay";
import EnterpriseNavBar from "./EnterpriseNavBar";
import { EnterpriseContext } from "./EnterpriseContext";
import { toast, ToastContainer } from "react-toastify";
import Tips from "../Tips";
import { Link, useNavigate } from "react-router-dom";
import truckyService from "../../common/services/trucky-service";
import CustomBadge from "../ui/CustomBadge";
import classnames from "classnames";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import Paginator from "../ui/Paginator";
import VTCMenu from "../../web/components/VTCMenu";

const ApplicationsManagement = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const company = globalContext.company;
  const member = globalContext.member;

  const es = new EnterpriseService();
  const [loadingData, setLoadingData] = useState(false);
  const [applications, setApplications] = useState([]);
  const [modalIsOpen, toggleModal] = useState(false);
  const [application, setApplication] = useState(null);
  const [logs, setUserLogs] = useState([]);
  const [activeTab, toggleTab] = useState("details");
  const [contactMessage, setContactMessage] = useState({
    message: "",
    add_cc_email: member.email,
  });
  const navigate = useNavigate();
  const [bans, setBans] = useState([]);
  const [showBansDialog, toggleBansDialog] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  const [totalPages, setTotalPages] = useState(0);
  const [searchParameters, setSearchParameters] = useState({
    name: "",
    status: "",
    discord: "",
  });

  useEffect(() => {
    truckyService.setDocumentTitle("Applications");
  }, []);

  useEffect(() => {
    getApplications();
  }, []);

  useEffect(() => {
    getApplications();
  }, [pagination.page, pagination.perPage]);

  useEffect(() => {
    if (application != null) {
      toggleModal(true);
      getUserLogs();
    }
  }, [application]);

  useEffect(() => {
    if (!modalIsOpen) {
      setApplication(null);
      setUserLogs([]);
      toggleTab("details");
    }
  }, [modalIsOpen]);

  useEffect(() => {
    getBans();
  }, [showBansDialog]);

  const getUserLogs = async () => {
    const result = await es.getUserLogs(application.user.id);
    setUserLogs(
      result.filter(
        (m) =>
          m.reason == "joined" ||
          m.reason == "kicked" ||
          m.reason == "got_kicked" ||
          m.reason == "left"
      )
    );
  };

  const getApplications = async () => {
    setLoadingData(true);
    const result = await es.getApplications(
      company,
      pagination,
      searchParameters
    );
    setLoadingData(false);

    if (!result.message) {
      setApplications(result.data);
      setTotalPages(result.last_page);
    }
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const getStatusBadge = (status) => {
    var color = "warning";

    if (status == "accepted") color = "success";
    if (status == "rejected") color = "danger";
    if (status == "in_progress") color = "info";
    if (status == "retired") color = "danger";

    return (
      <CustomBadge size="sm" color={color}>
        {container.localeManager.strings[status]}
      </CustomBadge>
    );
  };

  const accept = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.applicationConfirmation,
        { container: container }
      )
    ) {
      const result = await es.acceptApplication(company, application);
      if (result.success) {
        toggleModal(false);
        getApplications();
      } else {
        toast.error(result.message);
      }
    }
  };

  const reject = async () => {
    if (application.reject_reason != null && application.reject_reason != "") {
      if (
        await confirmWrapper(
          container.localeManager.strings.applicationRejection,
          { container: container }
        )
      ) {
        const result = await es.updateApplication(company, application);

        if (result.success) {
          const rejectResult = await es.rejectApplication(company, application);

          if (rejectResult.success) {
            toggleModal(false);
            getApplications();
          } else toast.error(result.message);
        } else toast.error(result.message);
      }
    } else {
      toast.error(container.localeManager.strings.pleaseFillRejectReason, {
        position: "top-right",
      });
    }
  };

  const save = async () => {
    const result = await es.updateApplication(company, application);
    if (result.success) {
      toggleModal(false);
      getApplications();
    } else {
      toast.error(result.message);
    }
  };

  const assignToMe = async () => {
    application.editor_user_id = member.id;
    application.status = "in_progress";

    const result = await es.updateApplication(company, application);

    if (result.success) {
      toggleModal(false);
      getApplications();
    } else {
      toast.error(result.message);
    }
  };

  const getLogMessage = (log) => {
    if (log.reason == "joined") {
      return container.localeManager.strings.formatString(
        container.localeManager.strings.companyJoined,
        log.company == null
          ? container.localeManager.strings.deletedCompany
          : log.company.name
      );
    }

    if (
      log.reason == "got_kicked" ||
      log.reason == "kicked" ||
      log.reason == "left"
    ) {
      return container.localeManager.strings.formatString(
        container.localeManager.strings.leftCompany,
        log.company == null
          ? container.localeManager.strings.deletedCompany
          : log.company.name
      );
    }
  };

  const sendApplicationContactMessage = async () => {
    const result = await es.sendApplicationContactMessage(
      company,
      application,
      contactMessage
    );

    if (result.success) {
      toggleModal(false);
      toast.success(container.localeManager.strings.messageSent);
    } else {
      toast.error(result.message);
    }
  };

  const getBans = async () => {
    const result = await es.bans(company.id);
    if (!result.message) setBans(result);
  };

  const ban = async (user_id) => {
    if (
      await confirmWrapper(container.localeManager.strings.banConfirmation, {
        container: container,
      })
    ) {
      setLoadingData(true);
      const result = await es.addBan(company.id, user_id);

      if (result.success) {
        toast.success(container.localeManager.strings.userHasBeenBanned);
      } else {
        toast.error(result.message);
      }
      setLoadingData(false);
    }
  };

  const removeBan = async (ban_id) => {
    const result = await es.removeBan(company.id, ban_id);
    if (result.success) getBans();
    else toast.error(result.message);
  };

  const handleRowsPerPageChange = (perPage) => {
    setPagination({ ...pagination, page: 1, perPage: perPage });
  };

  return (
    <LoadingOverlay
      active={loadingData}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        <VTCMenu active={"/applications"} container={container}></VTCMenu>

        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h5>{container.localeManager.strings.applications}</h5>
                    <Button
                      color="danger"
                      onClick={() => toggleBansDialog(true)}
                      size="sm"
                    >
                      <FontAwesomeIcon icon="ban"></FontAwesomeIcon>
                      {container.localeManager.strings.bans}
                    </Button>
                  </div>

                  {!loadingData && applications.length == 0 && (
                    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 my-3">
                      {container.localeManager.strings.noNewApplicationsHere}
                    </div>
                  )}
                  <FormGroup row>
                    <Col>
                      <Label>{container.localeManager.strings.username}</Label>
                      <Input
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            name: e.target.value,
                          })
                        }
                        value={searchParameters.name}
                      ></Input>
                    </Col>
                    <Col>
                      <Label>{container.localeManager.strings.status}</Label>
                      <Input
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            status: e.target.value,
                          })
                        }
                        value={searchParameters.status}
                        type="select"
                      >
                        <option value="">
                          {container.localeManager.strings.all}
                        </option>
                        <option value="accepted">
                          {container.localeManager.strings.accepted}
                        </option>
                        <option value="rejected">
                          {container.localeManager.strings.rejected}
                        </option>
                        <option value="retired">
                          {container.localeManager.strings.retired}
                        </option>
                      </Input>
                    </Col>
                    <Col>
                      <Label>{container.localeManager.strings.discord}</Label>
                      <Input
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            discord: e.target.value,
                          })
                        }
                        value={searchParameters.discord}
                      ></Input>
                    </Col>
                    <Col className="d-flex align-items-end">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={getApplications}
                      >
                        {container.localeManager.strings.search}
                      </Button>
                    </Col>
                  </FormGroup>
                  {!loadingData && applications.length > 0 && (
                    <>
                      <Paginator
                        rowsPerPageSelected={pagination.perPage}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        showRowsPerPage={true}
                        forcePage={pagination.page - 1}
                        totalPages={totalPages}
                        handlePageClick={handlePageClick}
                      ></Paginator>
                      <Table
                        className="table-row-dashed table-row-gray-300 align-middle gs-0 mt-3"
                        striped
                        size="sm"
                        responsive
                        hover
                      >
                        <thead>
                          <tr className="fw-bolder text-gray-700 bg-light">
                            <th scope="col" className="ps-4">
                              #
                            </th>
                            <th scope="col">
                              {container.localeManager.strings.date}
                            </th>
                            <th scope="col">
                              {container.localeManager.strings.user}
                            </th>
                            <th scope="col">
                              {container.localeManager.strings.status}
                            </th>
                            <th scope="col">
                              {container.localeManager.strings.recruiter}
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {applications.map((m) => {
                            return (
                              <tr>
                                <td className="ps-1">{m.id}</td>
                                <td>
                                  {container.localeManager
                                    .moment(m.created_at)
                                    .format("l")}
                                </td>
                                <td>
                                  {m.user.steam_profile != null && (
                                    <a
                                      title={
                                        container.localeManager.strings
                                          .steamProfile
                                      }
                                      target="_blank"
                                      href={`https://steamcommunity.com/profiles/${m.user.steam_profile.steam_id}`}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fab", "steam"]}
                                      ></FontAwesomeIcon>
                                    </a>
                                  )}
                                  {
                                    <Link to={`/user/${m.user.id}`} target="_blank">
                                      {m.user.name}
                                    </Link>
                                  }
                                </td>
                                <td>{getStatusBadge(m.status)}</td>
                                <td>{m.editor != null ? m.editor.name : ""}</td>
                                <td className="text-end" style={{ width: 150 }}>
                                  <Button
                                    size="sm"
                                    onClick={() => setApplication(m)}
                                    className="btn-icon w-30px h-30px ms-auto"
                                    color="active-light-primary"
                                    title={
                                      container.localeManager.strings.accept
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="me-0"
                                      icon="pencil-alt"
                                    ></FontAwesomeIcon>
                                  </Button>
                                  <Button
                                    size="sm"
                                    onClick={() =>
                                      navigate(
                                        `/messenger?user_id=${m.user.id}`
                                      )
                                    }
                                    className="ms-3 btn-icon w-30px h-30px ms-auto"
                                    color="active-light-primary"
                                    title={
                                      container.localeManager.strings
                                        .directMessage
                                    }
                                  >
                                    <FontAwesomeIcon
                                      className="me-0"
                                      icon="comment-dots"
                                    ></FontAwesomeIcon>
                                  </Button>
                                  <Button
                                    size="sm"
                                    onClick={() => ban(m.user.id)}
                                    className="ms-3 btn-icon w-30px h-30px ms-auto"
                                    color="active-light-danger"
                                    title={container.localeManager.strings.ban}
                                  >
                                    <FontAwesomeIcon
                                      className="me-0"
                                      icon="ban"
                                    ></FontAwesomeIcon>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <Paginator
                        rowsPerPageSelected={pagination.perPage}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        showRowsPerPage={true}
                        forcePage={pagination.page - 1}
                        totalPages={totalPages}
                        handlePageClick={handlePageClick}
                      ></Paginator>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              <Tips localeManager={container.localeManager}></Tips>
              {!window.overwolf && (
                <BoxDynamicAd container={container} className="my-3" />
              )}
            </aside>
          </Row>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {application != null && modalIsOpen && (
            <Modal
              zIndex="999999999"
              size="lg"
              isOpen={true}
              toggle={() => toggleModal(false)}
            >
              <ModalHeader
                toggle={() => toggleModal(false)}
                close={
                  <Button
                    color="active-light-primary"
                    className="btn-icon btn-sm w-30px h-30px ms-auto"
                    onClick={() => toggleModal(false)}
                  >
                    <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                  </Button>
                }
              >
                #{application.id} - {application.user.name}
              </ModalHeader>
              <ModalBody>
                {application.user.can_be_contacted_via_email && (
                  <Nav
                    className="nav-custom nav-line-tabs nav-line-tabs-2x fs-6 fw-bold mb-3"
                    tabs
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "details",
                        })}
                        onClick={() => {
                          toggleTab("details");
                        }}
                      >
                        {container.localeManager.strings.details}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "contact",
                        })}
                        onClick={() => {
                          toggleTab("contact");
                        }}
                      >
                        {container.localeManager.strings.contact}
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}
                <TabContent className="mt-3" activeTab={activeTab}>
                  <TabPane tabId="details">
                    <Row>
                      <Col sm={8}>
                        {application.user.steam_profile != null && (
                          <Row className="mb-3">
                            <Col sm={6}>
                              <Button
                                tag="a"
                                size="sm"
                                color="secondary"
                                target="_blank"
                                href={`https://steamcommunity.com/profiles/${application.user.steam_profile.steam_id}`}
                              >
                                <FontAwesomeIcon
                                  icon={["fab", "steam"]}
                                ></FontAwesomeIcon>
                                {
                                  container.localeManager.strings
                                    .viewSteamProfile
                                }
                              </Button>
                            </Col>
                            <Col sm={6} className="text-end">
                              <Button
                                tag="a"
                                size="sm"
                                color="secondary"
                                target="_blank"
                                href={`/user/${application.user.id}`}
                              >
                                {container.localeManager.strings.profileDetails}
                              </Button>
                            </Col>
                          </Row>
                        )}
                        {application.discord != null && (
                          <FormGroup>
                            <Label>Discord: {application.discord}</Label>
                          </FormGroup>
                        )}
                        <FormGroup>
                          <Label>
                            {container.localeManager.strings.messageFromTheUser}
                          </Label>
                          <div className="mb-3">{application.message}</div>
                        </FormGroup>
                        <FormGroup>
                          <Label>
                            {container.localeManager.strings.rejectReason}
                          </Label>
                          <textarea
                            className="form-control"
                            rows="5"
                            onChange={(e) =>
                              setApplication({
                                ...application,
                                reject_reason: e.target.value,
                              })
                            }
                          >
                            {application.reject_reason}
                          </textarea>
                        </FormGroup>
                        <FormGroup>
                          <Label>
                            {container.localeManager.strings.internalComment}
                          </Label>
                          <textarea
                            className="form-control"
                            rows="5"
                            onChange={(e) =>
                              setApplication({
                                ...application,
                                internal_comment: e.target.value,
                              })
                            }
                          >
                            {application.internal_comment}
                          </textarea>
                          <FormText className="text-muted">
                            {
                              container.localeManager.strings
                                .internalCommentHelpText
                            }
                          </FormText>
                        </FormGroup>
                        <FormText className="text-muted">
                          <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                          {container.localeManager.strings.assignToMeHelpText}
                        </FormText>
                        <FormGroup className="mt-3">
                          <div className="d-flex justify-content-between">
                            <div>
                              <Button
                                size="sm"
                                className="me-1"
                                onClick={() => save()}
                                color="primary"
                              >
                                <FontAwesomeIcon icon="save"></FontAwesomeIcon>
                                {container.localeManager.strings.save}
                              </Button>
                              {application.editor_user_id == null &&
                                application.status != "retired" && (
                                  <Button
                                    size="sm"
                                    className="me-1"
                                    onClick={() => assignToMe()}
                                    color="primary"
                                  >
                                    <FontAwesomeIcon icon="play"></FontAwesomeIcon>
                                    {container.localeManager.strings.assignToMe}
                                  </Button>
                                )}
                            </div>
                            {(application.status == "pending" ||
                              application.status == "in_progress") && (
                              <div>
                                <Button
                                  size="sm"
                                  className="me-1"
                                  onClick={() =>
                                    navigate(
                                      `/messenger?user_id=${application.user.id}`
                                    )
                                  }
                                  color="primary"
                                >
                                  <FontAwesomeIcon icon="comment-dots"></FontAwesomeIcon>
                                  {
                                    container.localeManager.strings
                                      .directMessage
                                  }
                                </Button>
                                <Button
                                  size="sm"
                                  className="me-1"
                                  onClick={() => reject()}
                                  color="danger"
                                >
                                  <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                                  {container.localeManager.strings.reject}
                                </Button>
                                <Button
                                  size="sm"
                                  onClick={() => accept()}
                                  className="me-1"
                                  color="success"
                                >
                                  <FontAwesomeIcon icon="check"></FontAwesomeIcon>
                                  {container.localeManager.strings.accept}
                                </Button>
                              </div>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        {logs.length > 0 && (
                          <div className="timeline-label user-activities">
                            {logs.map((m) => {
                              return (
                                <div className="timeline-item">
                                  <div className="timeline-label fw-bolder text-gray-800 fs-6">
                                    {container.localeManager
                                      .moment(m.created_at)
                                      .format("L")}
                                  </div>
                                  <div className="timeline-badge">
                                    <FontAwesomeIcon
                                      icon="genderless"
                                      className={`${
                                        m.reason == "joined"
                                          ? "text-success"
                                          : "text-danger"
                                      } fs-1`}
                                    ></FontAwesomeIcon>
                                  </div>
                                  <div className="timeline-content d-flex">
                                    <span className="fw-bolder text-gray-800 ps-3">
                                      {getLogMessage(m)}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {logs.length == 0 &&
                          container.localeManager.strings.noActiveLogs}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="contact">
                    <Row>
                      <Col>
                        <FormText color="muted" className="mb-3">
                          {
                            container.localeManager.strings
                              .applicationContactHint
                          }
                        </FormText>
                        <FormGroup>
                          <Label>
                            {container.localeManager.strings.message}
                          </Label>
                          <textarea
                            className="form-control"
                            rows="5"
                            onChange={(e) =>
                              setContactMessage({
                                ...contactMessage,
                                message: e.target.value,
                              })
                            }
                          >
                            {contactMessage.message}
                          </textarea>
                        </FormGroup>
                        <FormGroup>
                          <Label>
                            {container.localeManager.strings.addCcEmail}
                          </Label>
                          <InputGroup>
                            <Input
                              value={contactMessage.add_cc_email}
                              type="email"
                              onChange={(e) =>
                                setContactMessage({
                                  ...contactMessage,
                                  add_cc_email: e.target.value,
                                })
                              }
                            ></Input>
                            <Button
                              onClick={() =>
                                setContactMessage({
                                  ...contactMessage,
                                  add_cc_email: "",
                                })
                              }
                              color="danger"
                            >
                              {container.localeManager.strings.noThanks}
                            </Button>
                          </InputGroup>
                          <FormText color="muted">
                            {container.localeManager.strings.addCcEmailHint}
                          </FormText>
                        </FormGroup>
                        {contactMessage.message.trim() != "" && (
                          <FormGroup>
                            <Button
                              onClick={sendApplicationContactMessage}
                              color="primary"
                            >
                              {
                                container.localeManager.strings
                                  .sendMessageToUser
                              }
                            </Button>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </ModalBody>
            </Modal>
          )}
          {showBansDialog && (
            <Modal
              zIndex="999999999"
              size="lg"
              isOpen={true}
              toggle={() => toggleBansDialog(false)}
            >
              <ModalHeader
                toggle={() => toggleBansDialog(false)}
                close={
                  <Button
                    color="active-light-primary"
                    className="btn-icon btn-sm w-30px h-30px ms-auto"
                    onClick={() => toggleBansDialog(false)}
                  >
                    <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                  </Button>
                }
              >
                {container.localeManager.strings.bans}
              </ModalHeader>
              <ModalBody>
                <Table
                  className="table-row-dashed table-row-gray-300 align-middle gs-0 mt-3"
                  striped
                  size="sm"
                  responsive
                  hover
                >
                  <thead>
                    <tr className="fw-bolder text-gray-700 bg-light">
                      <th scope="col" className="ps-4">
                        {container.localeManager.strings.user}
                      </th>
                      <th scope="col">
                        {container.localeManager.strings.issuedBy2}
                      </th>
                      <th scope="col">
                        {container.localeManager.strings.date}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bans.map((m) => {
                      return (
                        <tr>
                          <td className="ps-4">{m.user.name}</td>
                          <td className="ps-1">{m.issued_by.name}</td>
                          <td className="ps-1">
                            {container.localeManager
                              .moment(m.created_at)
                              .format("L")}
                          </td>
                          <td>
                            <Button
                              size="sm"
                              onClick={() => removeBan(m.id)}
                              className="ms-3 btn-icon w-30px h-30px ms-auto"
                              color="active-light-danger"
                              title={container.localeManager.strings.removeBan}
                            >
                              <FontAwesomeIcon
                                className="me-0"
                                icon="trash-alt"
                              ></FontAwesomeIcon>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          )}
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default ApplicationsManagement;
