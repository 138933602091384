import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { ImageInputComponent } from "../common/plugins/metronic/components/_ImageInputComponent";
import EnterpriseService from "../common/services/enterprise-service";
import Select from "react-select";
import reactSelectCustomStyles from "../common/plugins/reactSelectStyles";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import Events from "../common/constants/events";
import { EnterpriseContext } from "./enterprise/EnterpriseContext";
import { confirmWrapper } from "./ui/askConfirm";
import { useNavigate } from "react-router";
import truckyService from "../common/services/trucky-service";
import classNames from "classnames";
import UserConnections from "./UserConnections";
import { useSearchParam } from "react-use";
import { useSearchParams } from "react-router-dom";

const EditProfile = ({ container }) => {
  const es = new EnterpriseService();
  const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
  const [user, setUser] = useState(globalContext.member);
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(searchParams.get('tab') ? searchParams.get('tab') : "details");

  useEffect(() => {
    truckyService.setDocumentTitle("Account Settings");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ImageInputComponent.bootstrap();
    }, 500);
  }, []);

  useEffect(() => {
    getCountries();
    getLanguages();
  }, []);

  const getCountries = async () => {
    setCountries(await es.getCountries());
  };

  const getLanguages = async () => {
    setLanguages(await es.getLanguages());
  };

  const save = async () => {
    var hasErrors = false;

    if (!truckyService.isValidWebhookUrl(user.discord_webhook_url)) {
      toast.error(container.localeManager.strings.discordWebhookUrlNotValid);
      return;
    }

    setLoading(true);

    const result = await es.updateProfile(user);

    if (result.error) {
      hasErrors = true;
      toast.error(result.message);
    } else {
      if (avatar != null) {
        const avatarResult = await es.uploadNewUserAvatar(user, avatar);
        if (avatarResult.error) {
          toast.error(avatarResult.message);
          hasErrors = true;
        }
      }

      if (!hasErrors) {
        toast.success(container.localeManager.strings.dataUpdated);
      }
    }

    if (!hasErrors) {
      setAvatar(null);

      await container.dataService.enterpriseService.loadEnterpriseMemberData(
        true
      );

      container.eventBus.trigger(Events.PROFILE_UPDATED, {});

      setUser(container.dataService.enterpriseService.member);

      setGlobalContext({
        ...globalContext,
        member: container.dataService.enterpriseService.member,
      });
    }

    setLoading(false);
  };

  const deleteAccount = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.deleteAccountConfirmation,
        { container }
      )
    ) {
      setLoading(true);
      const result = await es.deleteAccount();

      if (result.success) {
        container.dataService.logout();
        setGlobalContext({ member: null, company: null });
        navigate("/registration");
      } else {
        toast.error(result.message);
      }
      setLoading(false);
    }
  };

  const resetProfile = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.resetAccountConfirmation,
        { container }
      )
    ) {
      setLoading(true);
      await es.resetAccount();
      toast.success(container.localeManager.strings.operationCompleted);
      setLoading(false);
    }
  };

  const hideShowTag = (tag, state) => {
    let currentTagSettings = user.hide_tags;

    if (currentTagSettings == undefined || currentTagSettings == null) {
      currentTagSettings = [];
    }

    if (state) currentTagSettings.push(tag);
    else currentTagSettings = currentTagSettings.filter((m) => m != tag);

    setUser({ ...user, hide_tags: currentTagSettings });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      {user != null && (
        <Card>
          <CardBody>
            <Nav
              className="nav-custom nav-line-tabs nav-line-tabs-2x fs-6 fw-bold mb-3"
              tabs
            >
              <NavItem>
                <NavLink
                  className={classNames({ active: currentTab === "details" })}
                  onClick={() => {
                    setCurrentTab("details");
                  }}
                >
                  {container.localeManager.strings.details}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: currentTab === "connections",
                  })}
                  onClick={() => {
                    setCurrentTab("connections");
                  }}
                >
                  Connections
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: currentTab === "localizations",
                  })}
                  onClick={() => {
                    setCurrentTab("localizations");
                  }}
                >
                  {container.localeManager.strings.localizationSettings}
                </NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink
                  className={classNames({
                    "text-danger": true,
                    active: currentTab === "danger",
                  })}
                  onClick={() => {
                    setCurrentTab("danger");
                  }}
                >
                  {container.localeManager.strings.dangerZone}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={currentTab}>
              <TabPane tabId="details">
                <Row>
                  <Col sm="auto">
                    <div className="mb-7">
                      <label className="fs-6 fw-bold mb-2">
                        <span>
                          {container.localeManager.strings.changeAvatar}
                        </span>
                        <i
                          className="fas fa-exclamation-circle ms-1 fs-7"
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Allowed file types: png, jpg, jpeg."
                          aria-label="Allowed file types: png, jpg, jpeg."
                        ></i>
                      </label>
                      <div className="mt-1">
                        <div
                          id="kt_image_input_control"
                          className="image-input image-input-outline image-input-empty"
                          data-kt-image-input="true"
                        >
                          <div
                            className="image-input-wrapper w-125px h-125px"
                            style={{
                              backgroundImage: `url('${user.avatar_url}'`,
                            }}
                          ></div>
                          <label
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="change"
                          >
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              className="fs-7"
                            ></FontAwesomeIcon>
                            <input
                              onChange={(e) => setAvatar(e.target.files[0])}
                              type="file"
                              accept=".png, .jpg, .jpeg"
                            />
                          </label>
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="cancel"
                          >
                            <FontAwesomeIcon
                              icon="times"
                              className="fs-2"
                            ></FontAwesomeIcon>
                          </span>
                          <span
                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                            data-kt-image-input-action="remove"
                          >
                            <FontAwesomeIcon
                              icon="times"
                              className="fs-2"
                            ></FontAwesomeIcon>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{container.localeManager.strings.username}</Label>
                      <Input
                        type="text"
                        value={user.name}
                        onChange={(e) =>
                          setUser({ ...user, name: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>{container.localeManager.strings.email}</Label>
                      <Input
                        type="text"
                        value={user.email}
                        onChange={(e) =>
                          setUser({ ...user, email: e.target.value })
                        }
                      ></Input>
                      <FormText muted>
                        {container.localeManager.strings.avatarHint}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>{container.localeManager.strings.country}</Label>
                      <Select
                        onChange={(val) =>
                          setUser({
                            ...user,
                            country: val == null ? "" : val.code,
                          })
                        }
                        value={countries.find((m) => m.code == user.country)}
                        options={countries}
                        styles={reactSelectCustomStyles}
                        getOptionLabel={(m) => m.name}
                        getOptionValue={(m) => m.code}
                        isClearable={true}
                        backspaceRemovesValue={true}
                      ></Select>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>
                        {container.localeManager.strings.languageWithoutDots}
                      </Label>
                      <Select
                        onChange={(val) =>
                          setUser({
                            ...user,
                            language: val == null ? "" : val.name,
                          })
                        }
                        value={languages.find((m) => m.name == user.language)}
                        options={languages}
                        styles={reactSelectCustomStyles}
                        getOptionValue={(m) => m.code}
                        getOptionLabel={(m) => m.name}
                        isClearable={true}
                        backspaceRemovesValue={true}
                      ></Select>
                    </FormGroup>
                  </Col>
                </Row>
                <h5 className="mt-3">
                  {container.localeManager.strings.socials}
                </h5>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>Twitch</Label>
                      <Input
                        placeholder="https://www.twitch.tv/TruckyOfficial"
                        type="text"
                        value={user.twitch}
                        onChange={(e) =>
                          setUser({ ...user, twitch: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>Youtube</Label>
                      <Input
                        placeholder="https://www.youtube.com/c/TruckyOfficial"
                        type="text"
                        value={user.youtube}
                        onChange={(e) =>
                          setUser({ ...user, youtube: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>Twitter</Label>
                      <Input
                        placeholder="https://www.twitter.com/TruckyOfficial"
                        type="text"
                        value={user.twitter}
                        onChange={(e) =>
                          setUser({ ...user, twitter: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>Facebook</Label>
                      <Input
                        placeholder="https://www.facebook.com/truckyofficial"
                        type="text"
                        value={user.facebook}
                        onChange={(e) =>
                          setUser({ ...user, facebook: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>
                        {container.localeManager.strings.wotrProfile}
                      </Label>
                      <Input
                        type="text"
                        placeholder="https://www.worldoftrucks.com/en/profile/446013"
                        value={user.wotr}
                        onChange={(e) =>
                          setUser({ ...user, wotr: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>{container.localeManager.strings.website}</Label>
                      <Input
                        type="text"
                        placeholder="https://truckyapp.com"
                        value={user.website}
                        onChange={(e) =>
                          setUser({ ...user, website: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label>
                        {container.localeManager.strings.discordServerInvite}
                      </Label>
                      <Input
                        type="text"
                        placeholder="https://discord.gg/trucky or https://discord.gg/invite/a49hgja"
                        value={user.discord_server}
                        onChange={(e) =>
                          setUser({ ...user, discord_server: e.target.value })
                        }
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}></Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        {container.localeManager.strings.discord_webhook_url}
                      </Label>
                      <InputGroup>
                        <Input
                          type="text"
                          placeholder={
                            user.has_discord_webhook_url
                              ? "************************"
                              : ""
                          }
                          value={user.discord_webhook_url}
                          onChange={(e) =>
                            setUser({
                              ...user,
                              discord_webhook_url: e.target.value,
                            })
                          }
                        ></Input>
                        <Button
                          onClick={() =>
                            setUser({ ...user, discord_webhook_url: null })
                          }
                          color="secondary"
                        >
                          {container.localeManager.strings.remove}
                        </Button>
                      </InputGroup>
                      <FormText color="muted">
                        {
                          container.localeManager.strings
                            .discord_webhook_urlInfo
                        }
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <h5 className="mt-3">
                  {container.localeManager.strings.notificationSettings}
                </h5>
                <Row>
                  <Col sm={6}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) => {
                          setUser({
                            ...user,
                            receive_email_notifications: e.target.checked,
                          });
                        }}
                        class="form-check-input"
                        type="checkbox"
                        checked={user.receive_email_notifications}
                      />
                      <span class="form-check-label">
                        {
                          container.localeManager.strings
                            .receiveNotificationsEmail
                        }
                      </span>
                    </label>
                  </Col>
                  <Col sm={6}></Col>
                </Row>
                <h5 className="mt-3">
                  {container.localeManager.strings.tagsSettings}
                </h5>
                <FormGroup row>
                  <Col sm={3}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          hideShowTag("driver_style", e.target.checked)
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          user.hide_tags != null
                            ? user.hide_tags.includes("driver_style")
                            : false
                        }
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.hideStatsTypeTag}
                      </span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          hideShowTag("truck_lover_ets2", e.target.checked)
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          user.hide_tags != null
                            ? user.hide_tags.includes("truck_lover_ets2")
                            : false
                        }
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.hideETS2Truck}
                      </span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          hideShowTag("truck_lover_ats", e.target.checked)
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          user.hide_tags != null
                            ? user.hide_tags.includes("truck_lover_ats")
                            : false
                        }
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.hideATSTruck}
                      </span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          hideShowTag("long_hauler", e.target.checked)
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          user.hide_tags != null
                            ? user.hide_tags.includes("long_hauler")
                            : false
                        }
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.hideLongHaulerTag}
                      </span>
                    </label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={3}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          hideShowTag("driver_type", e.target.checked)
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          user.hide_tags != null
                            ? user.hide_tags.includes("driver_type")
                            : false
                        }
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.hidePlayerStyleTag}
                      </span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          hideShowTag("workaholic", e.target.checked)
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          user.hide_tags != null
                            ? user.hide_tags.includes("workaholic")
                            : false
                        }
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.hideWorkaholicTag}
                      </span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) =>
                          hideShowTag("trailer_addiction", e.target.checked)
                        }
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          user.hide_tags != null
                            ? user.hide_tags.includes("trailer_addiction")
                            : false
                        }
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.hideCargoTag}
                      </span>
                    </label>
                  </Col>
                </FormGroup>
                <h5 className="mt-5">
                  {container.localeManager.strings.profileSettings}
                </h5>
                <Row>
                  <Col sm={6}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) => {
                          setUser({
                            ...user,
                            display_vtc_history: e.target.checked,
                          });
                        }}
                        class="form-check-input"
                        type="checkbox"
                        checked={user.display_vtc_history}
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.displayVTCHistory}
                      </span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input
                        onChange={(e) => {
                          setUser({
                            ...user,
                            display_linked_discord: e.target.checked,
                          });
                        }}
                        class="form-check-input"
                        type="checkbox"
                        checked={user.display_linked_discord}
                      />
                      <span class="form-check-label">
                        {container.localeManager.strings.displayDiscord}
                      </span>
                    </label>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="localizations">
                <h5 className="mt-5">
                  {container.localeManager.strings.aggregatedStats}
                </h5>
                <FormText muted className="mb-3">
                  {container.localeManager.strings.aggregatedStatsInfo}
                </FormText>
                <FormGroup row>
                  <Col sm={4}>
                    <Label>{container.localeManager.strings.distance}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          aggregated_distance_unit: e.target.value,
                        })
                      }
                      value={user.aggregated_distance_unit}
                    >
                      <option value="km">
                        {container.localeManager.strings.kilometers}
                      </option>
                      <option value="mi">
                        {container.localeManager.strings.miles}
                      </option>
                      <option value="sca-mi">
                        {container.localeManager.strings.scandinavianMile}
                      </option>
                    </Input>
                  </Col>
                  <Col sm={4}>
                    <Label>{container.localeManager.strings.weightUnit}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          aggregated_weight_unit: e.target.value,
                        })
                      }
                      value={user.aggregated_weight_unit}
                    >
                      <option value="t">
                        {container.localeManager.strings.tons}
                      </option>
                      <option value="lb">
                        {container.localeManager.strings.libres}
                      </option>
                    </Input>
                  </Col>
                  <Col sm={4}>
                    <Label>{container.localeManager.strings.volumeUnit}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          aggregated_volume_unit: e.target.value,
                        })
                      }
                      value={user.aggregated_volume_unit}
                    >
                      <option value="l">
                        {container.localeManager.strings.litres}
                      </option>
                      <option value="gal">
                        {container.localeManager.strings.usGallons}
                      </option>
                      <option value="gal-uk">
                        {container.localeManager.strings.imperialGallons}
                      </option>
                    </Input>
                  </Col>
                </FormGroup>

                <h5 className="mt-5">Euro Truck Simulator 2</h5>
                <FormText muted className="mb-3">
                  {container.localeManager.strings.gameStatsInfo}
                </FormText>
                <FormGroup row>
                  <Col sm={3}>
                    <Label>{container.localeManager.strings.distance}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({ ...user, ets2_distance_unit: e.target.value })
                      }
                      value={user.ets2_distance_unit}
                    >
                      <option value="km">
                        {container.localeManager.strings.kilometers}
                      </option>
                      <option value="mi">
                        {container.localeManager.strings.miles}
                      </option>
                      <option value="sca-mi">
                        {container.localeManager.strings.scandinavianMile}
                      </option>
                    </Input>
                  </Col>
                  <Col sm={3}>
                    <Label>{container.localeManager.strings.weightUnit}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({ ...user, ets2_weight_unit: e.target.value })
                      }
                      value={user.ets2_weight_unit}
                    >
                      <option value="t">
                        {container.localeManager.strings.tons}
                      </option>
                      <option value="lb">
                        {container.localeManager.strings.libres}
                      </option>
                    </Input>
                  </Col>
                  <Col sm={3}>
                    <Label>{container.localeManager.strings.volumeUnit}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({ ...user, ets2_volume_unit: e.target.value })
                      }
                      value={user.ets2_volume_unit}
                    >
                      <option value="l">
                        {container.localeManager.strings.litres}
                      </option>
                      <option value="gal">
                        {container.localeManager.strings.usGallons}
                      </option>
                      <option value="gal-uk">
                        {container.localeManager.strings.imperialGallons}
                      </option>
                    </Input>
                  </Col>
                  <Col sm={3}>
                    <Label>
                      {container.localeManager.strings.fuel_economy}
                    </Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          ets2_fuel_economy_unit: e.target.value,
                        })
                      }
                      value={user.ets2_fuel_economy_unit}
                    >
                      <option value="l100km">
                        {container.localeManager.strings.l100km}
                      </option>
                      <option value="mpg">
                        {container.localeManager.strings.mpg}
                      </option>
                      <option value="mpukg">
                        {container.localeManager.strings.mpukg}
                      </option>
                      <option value="kml">
                        {container.localeManager.strings.kml}
                      </option>
                      <option value="l100mil">
                        {container.localeManager.strings.l100mil}
                      </option>
                    </Input>
                  </Col>
                </FormGroup>

                <h5 className="mt-5">American Truck Simulator</h5>
                <FormText muted className="mb-3">
                  {container.localeManager.strings.gameStatsInfo}
                </FormText>
                <FormGroup row>
                  <Col sm={3}>
                    <Label>{container.localeManager.strings.distance}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({ ...user, ats_distance_unit: e.target.value })
                      }
                      value={user.ats_distance_unit}
                    >
                      <option value="km">
                        {container.localeManager.strings.kilometers}
                      </option>
                      <option value="mi">
                        {container.localeManager.strings.miles}
                      </option>
                      <option value="sca-mi">
                        {container.localeManager.strings.scandinavianMile}
                      </option>
                    </Input>
                  </Col>
                  <Col sm={3}>
                    <Label>{container.localeManager.strings.weightUnit}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({ ...user, ats_weight_unit: e.target.value })
                      }
                      value={user.ats_weight_unit}
                    >
                      <option value="t">
                        {container.localeManager.strings.tons}
                      </option>
                      <option value="lb">
                        {container.localeManager.strings.libres}
                      </option>
                    </Input>
                  </Col>
                  <Col sm={3}>
                    <Label>{container.localeManager.strings.volumeUnit}</Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({ ...user, ats_volume_unit: e.target.value })
                      }
                      value={user.ats_volume_unit}
                    >
                      <option value="l">
                        {container.localeManager.strings.litres}
                      </option>
                      <option value="gal">
                        {container.localeManager.strings.usGallons}
                      </option>
                      <option value="gal-uk">
                        {container.localeManager.strings.imperialGallons}
                      </option>
                    </Input>
                  </Col>
                  <Col sm={3}>
                    <Label>
                      {container.localeManager.strings.fuel_economy}
                    </Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          ats_fuel_economy_unit: e.target.value,
                        })
                      }
                      value={user.ats_fuel_economy_unit}
                    >
                      <option value="l100km">
                        {container.localeManager.strings.l100km}
                      </option>
                      <option value="mpg">
                        {container.localeManager.strings.mpg}
                      </option>
                      <option value="mpukg">
                        {container.localeManager.strings.mpukg}
                      </option>
                      <option value="kml">
                        {container.localeManager.strings.kml}
                      </option>
                      <option value="l100mil">
                        {container.localeManager.strings.l100mil}
                      </option>
                    </Input>
                  </Col>
                </FormGroup>
              </TabPane>
              <TabPane tabId="danger">
                <div>
                  <div className="mb-7 text-center">
                    <div className="notice bg-light-warning rounded border-warning border border-dashed p-6 mb-3 mt-3">
                      <FontAwesomeIcon icon="warning"></FontAwesomeIcon>
                      {container.localeManager.strings.resetProfileInfo}
                    </div>
                    <Button onClick={resetProfile} color="danger">
                      {container.localeManager.strings.resetProfile}
                    </Button>
                  </div>
                  <div className="text-center">
                    <div className="notice bg-light-warning rounded border-warning border border-dashed p-6 mb-3 mt-3">
                      <FontAwesomeIcon icon="warning"></FontAwesomeIcon>
                      {container.localeManager.strings.deleteProfileInfo}
                    </div>
                    <Button onClick={deleteAccount} color="danger">
                      {container.localeManager.strings.deleteAccount}
                    </Button>
                  </div>
                </div>
              </TabPane>
              {currentTab == "connections" && (
                <TabPane tabId="connections">
                  <UserConnections container={container}></UserConnections>
                </TabPane>
              )}
            </TabContent>
            {currentTab != "danger" && currentTab != 'connections' && (
              <div className="d-flex justify-content-between mt-5">
                <Button onClick={save} color="primary">
                  {container.localeManager.strings.save}
                </Button>
              </div>
            )}
          </CardBody>
        </Card>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </LoadingOverlay>
  );
};

export default EditProfile;
