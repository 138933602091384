function getUnit(user, game_id, what) {
    let game = 'aggregated';

    if (game_id == 'ets2')
        game = 'ets2';

    if (game_id == 'ats')
        game = 'ats';
    
    if (game_id == 1)
        game = 'ets2';

    if (game_id == 2)
        game = 'ats';

    if (user == null) {
        if (game == 'ets2' || game == 'aggregated') {
            switch (what) {
                case 'distance':
                    return 'km';
                case 'volume':
                    return 'l';
                case 'weight':
                    return 't';
                case 'fuel_economy':
                    return 'l100km';
            }
        }

        if (game == 'ats') {
            switch (what) {
                case 'distance':
                    return 'mi';
                case 'volume':
                    return 'gal';
                case 'weight':
                    return 'lb';
                case 'fuel_economy':
                    return 'mpg';
            }
        }
    }
    else {
        return user[`${game}_${what}_unit`];
    }
}

function getUnitLabel(to, source) {
    let abbr = to;

    if (source == 'l' && to == 'gal-uk') {
        abbr = 'gal';
    }

    if (source == 'km' && to == 'sca-mi') {
        abbr = 'mil';
    }

    return abbr;
}

function performConversion(value, to, source) {
    let converted = value;

    if (source == 'km' && to == 'mi') {
        converted = value / 1.609;
    }

    if (source == 'l' && to == 'gal') {
        converted = value / 3.785;
    }

    if (source == 'l' && to == 'gal-uk') {
        converted = value / 4.546;
    }

    if (source == 't' && to == 'lb') {
        converted = value * 2205;
    }

    if (source == 'km' && to == 'sca-mi') {
        converted = value / 10;
    }

    return Math.round(converted);
}

function formatConversion(value, to, source) {

    let abbr = getUnitLabel(to, source);
    let converted = performConversion(value, to, source);

    return window.localeManager.numeral(converted).format('0,0') + ' ' + abbr;
}

function convertDistance(user, game_id, value, source = 'km') {
    let unit = getUnit(user, game_id, 'distance');
    return formatConversion(value, unit, source);
}

function convertWeight(user, game_id, value, source = 't') {
    let unit = getUnit(user, game_id, 'weight');
    return formatConversion(value, unit, source);
}

function convertVolume(user, game_id, value, source = 'l') {
    let unit = getUnit(user, game_id, 'volume');
    return formatConversion(value, unit, source);
}

function convertDistanceValue(user, game_id, value, source = 'km') {
    let unit = getUnit(user, game_id, 'distance');
    return performConversion(value, unit, source);
}

function convertWeightValue(user, game_id, value, source = 't') {
    let unit = getUnit(user, game_id, 'weight');
    return performConversion(value, unit, source);
}

function convertVolumeValue(user, game_id, value, source = 'l') {
    let unit = getUnit(user, game_id, 'volume');
    return performConversion(value, unit, source);
}

function getDistanceUnitLabel(user, game_id, source = 'km') {
    let unit = getUnit(user, game_id, 'distance');
    return getUnitLabel(unit, source);
}

function getWeightUnitLabel(user, game_id, source = 't') {
    let unit = getUnit(user, game_id, 'weight');
    return getUnitLabel(unit, source);
}

function getVolumeUnitLabel(user, game_id, source = 'l') {
    let unit = getUnit(user, game_id, 'volume');
    return getUnitLabel(unit, source);
}

function getSpeedUnitLabel(user, game_id) {
    let unit = getUnit(user, game_id, 'distance');
    if (unit == 'km')
        return 'km/h';
    else
        return 'mph';
}

function convertFuelConsumption(user, game_id, distance_km, fuel_used_l) {

    /*
    "l100km": "l/100km",
    "mpg": "mpg (US Gallons)",
    "mpukg": "mpg (Imperial Gallons)",
    "kml": "km/l",
    "l100mil": "l/100mil (Scandinavian Mile)"
    */

    let value = getFuelConsumptionValue(user, game_id, distance_km, fuel_used_l);
    let unit = getFuelConsumptionUnit(user, game_id);

    return `${window.localeManager.numeral(value).format('0,0.[0]')} ${unit}`
    /*
    if (unit == 'l100km') {
        return `${window.localeManager.numeral((fuel_used_l / distance_km) * 100).format('0,0.[0]')} ${getFuelConsumptionUnit(user, game_id)}`;
    }
    else if (unit == 'mpg') {
        let distanceConverted = performConversion(distance_km, 'mi', 'km');
        let fuelConverted = performConversion(fuel_used_l, 'gal', 'l');
        return `${window.localeManager.numeral(distanceConverted / fuelConverted).format('0,0.[0]')} ${getFuelConsumptionUnit(user, game_id)}`;
    }
    else if (unit == 'mpukg') {
        let distanceConverted = performConversion(distance_km, 'mi', 'km');
        let fuelConverted = performConversion(fuel_used_l, 'gal-uk', 'l');
        return `${window.localeManager.numeral(distanceConverted / fuelConverted).format('0,0.[0]')} ${getFuelConsumptionUnit(user, game_id)}`;
    }
    else if (unit == 'kml') {
        return `${window.localeManager.numeral(distance_km / fuel_used_l).format('0,0.[0]')} ${getFuelConsumptionUnit(user, game_id)}`;
    }
    else if (unit == 'l100mil') {
        let distanceConverted = performConversion(distance_km, 'sca-mi', 'km');
        return `${window.localeManager.numeral((fuel_used_l / distanceConverted) * 100).format('0,0.[0]')} ${getFuelConsumptionUnit(user, game_id)}`;
    }
    */
}

function getFuelConsumptionValue(user, game_id, distance_km, fuel_used_l) {
    const unit = getUnit(user, game_id, 'fuel_economy');
    if (unit == 'l100km') {
        return (fuel_used_l / distance_km) * 100;
    }
    else if (unit == 'mpg') {
        let distanceConverted = performConversion(distance_km, 'mi', 'km');
        let fuelConverted = performConversion(fuel_used_l, 'gal', 'l');
        return distanceConverted / fuelConverted;
    }
    else if (unit == 'mpukg') {
        let distanceConverted = performConversion(distance_km, 'mi', 'km');
        let fuelConverted = performConversion(fuel_used_l, 'gal-uk', 'l');
        return distanceConverted / fuelConverted;
    }
    else if (unit == 'kml') {
        return distance_km / fuel_used_l;
    }
    else if (unit == 'l100mil') {
        let distanceConverted = performConversion(distance_km, 'sca-mi', 'km');
        return (fuel_used_l / distanceConverted) * 100;
    }
}

function getFuelConsumptionUnit(user, game_id) {
    const unit = getUnit(user, game_id, 'fuel_economy');
    if (unit == 'l100km') {
        return 'l/100km';
    }
    else if (unit == 'mpg') {
        return 'mpg';
    }
    else if (unit == 'mpukg') {

        return 'mpg';
    }
    else if (unit == 'kml') {
        return `km/l`;
    }
    else if (unit == 'l100mil') {
        return `l/100mil`;
    }
}

export default {
    convertDistance,
    convertWeight,
    convertVolume,
    convertDistanceValue,
    convertWeightValue,
    convertVolumeValue,
    getDistanceUnitLabel,
    getWeightUnitLabel,
    getVolumeUnitLabel,
    getSpeedUnitLabel,
    convertFuelConsumption,
    getFuelConsumptionValue,
    getFuelConsumptionUnit
};