import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { ButtonGroup, Alert, Button, Col, Row, Spinner, Card, CardBody, Navbar, Nav, NavItem, NavLink, TabContent, TabPane, ModalHeader, ModalBody, FormGroup, Label, Input, Badge, CardImg, CardTitle, PaginationItem, PaginationLink, Pagination } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import Select from 'react-select';
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import CompanyDetail from "./CompanyDetail";
import { useNavigate } from "react-router";
import Paginator from "../ui/Paginator";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import truckyService from "../../common/services/trucky-service";
import CustomBadge from "../ui/CustomBadge";
import moment from "moment";
import { Link } from "react-router-dom";
import RandomCompanies from "./RandomCompanies";
import config from "../../config";
import BoxDynamicAd from "../../ads/BoxDynamicAd";

const classnames = require('classnames');

const Directory = ({ container }) => {

    const navigate = useNavigate();
    const es = new EnterpriseService();
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [searchParameters, setSearchParameters] = useState(truckyService.loadSearchParameters('enterprise_directory', { name: '', country_code: '', company_type: '', language: '', recruitment: '', game: 1, stats_type: 'real_miles' }));
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, perPage: 18 });
    const [totalPages, setTotalPages] = useState(0);
    const [searchMode, setSearchMode] = useState(truckyService.loadSearchParameters('enterprise_directory_mode', 'browse'));
    const [currentPeriod, setCurrentPeriod] = useState({ month: moment().month(), year: moment().year() });
    const [companyStats, setCompanyStats] = useState(null);

    useEffect(() => {
        truckyService.saveSearchParameters('enterprise_directory', searchParameters);
    }, [searchParameters]);

    useEffect(() => {
        truckyService.saveSearchParameters('enterprise_directory_mode', searchMode);
    }, [searchMode]);

    useEffect(() => {
        getCompanies();
    }, [pagination, searchMode, currentPeriod]);

    useEffect(() => {

        truckyService.setDocumentTitle('VTC Directory');

        getCountries();
        getLanguages();
        es.getGlobalCompanyStats().then(setCompanyStats);
    }, []);

    const getCountries = async () => {
        setCountries(await es.getCountries());
    }

    const getLanguages = async () => {
        setLanguages(await es.getLanguages());
    }

    const getCompanies = async () => {
        setLoading(true);

        var result;

        if (searchMode == 'browse') {
            result = await es.getCompanies(searchParameters, pagination);
        }
        else if (searchMode == 'leaderboards') {
            let searchParametersWithPeriod = { ...searchParameters, month: currentPeriod.month + 1, year: currentPeriod.year };
            result = await es.getCompaniesLeaderboards(searchParametersWithPeriod, pagination);
        }
        else if (searchMode == 'hardcore') {
            let searchParametersWithPeriod = { ...searchParameters, month: currentPeriod.month + 1, year: currentPeriod.year };
            result = await es.getCompaniesHardcoreLeaderboards(searchParametersWithPeriod, pagination);
        }

        if (result != null) {
            setCompanies(result.data);
            setTotalPages(result.last_page);
        }

        setLoading(false);
    };

    const getCompanyStyleBadge = (company) => {
        if (company.company_type == 'realistic')
            return <CustomBadge size="small" color="primary">{container.localeManager.strings.realisticEconomy} <FontAwesomeIcon className="ms-1" icon="users"></FontAwesomeIcon>{company.members_count}</CustomBadge>;

        if (company.company_type == 'miles')
            return <CustomBadge size="small" color="primary">{container.localeManager.strings.enterpriseMiles} <FontAwesomeIcon className="ms-1" icon="users"></FontAwesomeIcon>{company.members_count}</CustomBadge>;

        if (company.company_type == 'both')
            return <CustomBadge size="small" color="primary">{container.localeManager.strings.ecoAndMiles} <FontAwesomeIcon className="ms-1" icon="users"></FontAwesomeIcon>{company.members_count}</CustomBadge>;
    }

    const search = () => {
        setPagination({ ...pagination, page: 1 });
    };

    const getCompanyLogo = (company) => {
        if (company.avatar_url == null)
            return <img class="mb-3" style={{ maxHeight: 100, alignSelf: 'center' }} src="assets/company-placeholder.png" />
        else
            return <img class="mb-3" style={{ maxHeight: 100, alignSelf: 'center' }} src={company.avatar_url} onError={(el) => el.src = "assets/company-placeholder.png"} />
    }

    const handlePageClick = (event) => {
        setPagination({ ...pagination, page: event.selected + 1 });
    };

    const changeSearchMode = (mode) => {
        setSearchMode(mode);
        setPagination({ ...pagination, page: 1 });
    };

    const nextPeriod = () => {
        var newDate = moment().year(currentPeriod.year).month(currentPeriod.month).add(1, 'month');

        setCurrentPeriod({ month: newDate.month(), year: newDate.year() });
    }

    const prevPeriod = () => {
        var newDate = moment().year(currentPeriod.year).month(currentPeriod.month).subtract(1, 'month');

        setCurrentPeriod({ month: newDate.month(), year: newDate.year() });
    }

    const isPrevDisabled = () => {
        return false;
    }

    const isNextDisabled = () => {
        var newDate = moment().year(currentPeriod.year).month(currentPeriod.month).add(1, 'month');
        var endDate = moment().add(1, 'month');

        if (newDate.month() == endDate.month() && newDate.year() == endDate.year())
            return true;

        return false;
    }

    const getPeriodDescription = () => {
        return (<>
            {container.localeManager.moment().year(currentPeriod.year).month(currentPeriod.month).format("MMMM")} {searchParameters.year}
        </>);
    }

    const handleRowsPerPageChange = (perPage) => {
        setPagination({ ...pagination, page: 1, perPage: perPage });
      };

    return (
        <div>
            <LoadingOverlay
                active={loading}
                spinner={<Spinner color="primary" style={{ width: '4rem', height: '4rem' }}></Spinner>}
            >
                <Row noGutters id="enterpriseContainer">
                    <Col>
                        <Card className="h-100">
                            <CardBody>
                                <>
                                    <div className="mb-3">
                                        <Row>
                                            <Col>
                                                <ButtonGroup className="w-100 mb-3">
                                                    <Button color={searchMode == 'browse' ? 'primary' : 'secondary'} onClick={() => changeSearchMode('browse')}>{container.localeManager.strings.browse}</Button>
                                                    <Button color={searchMode == 'leaderboards' ? 'primary' : 'secondary'} onClick={() => changeSearchMode('leaderboards')}>{container.localeManager.strings.distanceLeaderboards}</Button>
                                                    {config.ENABLE_PROFILE_TRACKING &&
                                                        <Button color={searchMode == 'hardcore' ? 'primary' : 'secondary'} onClick={() => changeSearchMode('hardcore')}>{container.localeManager.strings.hardcoreLeaderboards}</Button>
                                                    }
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup row>
                                            {searchMode == 'browse' &&
                                                <>
                                                    <RandomCompanies container={container} cards={false} carousel={true}></RandomCompanies>
                                                    <Col sm={6}>
                                                        <Label>{container.localeManager.strings.companyStyle}</Label>
                                                        <Input value={searchParameters.company_type} onChange={(e) => setSearchParameters({ ...searchParameters, company_type: e.target.value })} type="select">
                                                            <option value="">{container.localeManager.strings.all}</option>
                                                            <option value="realistic">{container.localeManager.strings.realisticEconomy}</option>
                                                            <option value="miles">{container.localeManager.strings.enterpriseMiles}</option>
                                                            <option value="both">{container.localeManager.strings.ecoAndMiles}</option>
                                                        </Input>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Label>{container.localeManager.strings.name}</Label>
                                                        <Input value={searchParameters.name} type="text" onChange={(e) => setSearchParameters({ ...searchParameters, name: e.target.value })}></Input>
                                                    </Col>
                                                </>
                                            }
                                            {(searchMode == 'leaderboards' || searchMode == 'hardcore') &&
                                                <>
                                                    <Col sm={6}>
                                                        <Label>{container.localeManager.strings.game}</Label>
                                                        <Input value={searchParameters.game} onChange={(e) => setSearchParameters({ ...searchParameters, game: e.target.value })} type="select">
                                                            <option value="1">Euro Truck Simulator 2</option>
                                                            <option value="2">American Truck Simulator</option>
                                                        </Input>
                                                    </Col>
                                                    {searchMode == 'leaderboards' &&
                                                        <Col sm={6}>
                                                            <Label>{container.localeManager.strings.statsType}</Label>
                                                            <Input value={searchParameters.stats_type} onChange={(e) => setSearchParameters({ ...searchParameters, stats_type: e.target.value })} type="select">
                                                                <option value="total">{container.localeManager.strings.total}</option>
                                                                <option value="real_miles">{container.localeManager.strings.realMiles}</option>
                                                                <option value="race_miles">{container.localeManager.strings.raceMiles}</option>
                                                                <option value="wotr">WOTR</option>
                                                                <option value="truckersmp">TruckersMP</option>
                                                                <option value="transport_system">{container.localeManager.strings.public_transport}</option>
                                                            </Input>
                                                        </Col>
                                                    }
                                                </>
                                            }
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={4}>
                                                <Label>{container.localeManager.strings.country}</Label>
                                                <Select
                                                    onChange={(val) => setSearchParameters({ ...searchParameters, country_code: val == null ? '' : val.code })}
                                                    value={countries.find(m => m.code == searchParameters.country_code)}
                                                    options={countries}
                                                    styles={reactSelectCustomStyles}
                                                    getOptionLabel={m => m.name}
                                                    getOptionValue={m => m.code}
                                                    isClearable={true}
                                                    backspaceRemovesValue={true}
                                                ></Select>
                                            </Col>
                                            {searchMode == 'browse' &&
                                                <>
                                                    <Col sm={4}>
                                                        <Label>{container.localeManager.strings.languageWithoutDots}</Label>
                                                        <Select
                                                            onChange={(val) => setSearchParameters({ ...searchParameters, language: val == null ? '' : val.name })}
                                                            value={languages.find(m => m.name == searchParameters.language)}
                                                            options={languages}
                                                            styles={reactSelectCustomStyles}
                                                            getOptionValue={m => m.code}
                                                            getOptionLabel={m => m.name}
                                                            isClearable={true}
                                                            backspaceRemovesValue={true}
                                                        ></Select>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <Label>{container.localeManager.strings.recruitmentStatus}</Label>
                                                        <Input value={searchParameters.recruitment} onChange={(e) => setSearchParameters({ ...searchParameters, recruitment: e.target.value })} type="select" >
                                                            <option value=""> {container.localeManager.strings.all}</option>
                                                            <option value="open"> {container.localeManager.strings.open}</option>
                                                            <option value="closed"> {container.localeManager.strings.closed}</option>
                                                        </Input>
                                                    </Col>
                                                </>
                                            }
                                            <Col className="d-flex align-items-end justify-content-end mt-3 mt-md-none">
                                                <Button size="sm" onClick={search} color="primary"> {container.localeManager.strings.search}</Button>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    {!loading &&
                                        <>
                                            <div className="my-3">
                                                <Paginator rowsPerPageSelected={pagination.perPage} rowsPerPage={[18, 36, 54, 72, 90]} handleRowsPerPageChange={handleRowsPerPageChange} showRowsPerPage={true} forcePage={pagination.page - 1} totalPages={totalPages} handlePageClick={handlePageClick}>
                                                    {!loading && (searchMode == 'leaderboards' || searchMode == 'hardcore') &&
                                                        <>
                                                            <Button disabled={isPrevDisabled()} className="btn-icon w-30px h-30px me-3" color="active-light-primary" onClick={() => prevPeriod()} size="sm"><FontAwesomeIcon className="me-0" icon="chevron-left"></FontAwesomeIcon></Button>
                                                            {getPeriodDescription()}
                                                            <Button disabled={isNextDisabled()} className="btn-icon w-30px h-30px ms-3" color="active-light-primary" onClick={() => nextPeriod()} size="sm"><FontAwesomeIcon className="me-0" icon="chevron-right"></FontAwesomeIcon></Button>
                                                        </>
                                                    }
                                                </Paginator>
                                            </div>
                                            <div class="grid cols-3 no-gap">
                                                {companies.map(m => {
                                                    return (
                                                        <Card className="bg-light directory-company-card text-white">
                                                            <CardBody className="text-center d-flex flex-column">
                                                                <Link to={`/company/${m.id}`} className="text-white">
                                                                    {(searchMode == 'leaderboards' || searchMode == 'hardcore') && <CustomBadge className="company-leaderboard-position-badge" color="primary">{m.position}</CustomBadge>}
                                                                    {getCompanyLogo(m)}
                                                                    <CardTitle className="text-uppercase font-weight-bold"><h4>{m.name}</h4></CardTitle>
                                                                    {searchMode == 'browse' &&
                                                                        <p className="mt-1" class="font-italic">
                                                                            {m.slogan}
                                                                        </p>
                                                                    }
                                                                    <div className="mt-auto">
                                                                        {m.flag_url != null &&
                                                                            <img src={m.flag_url} className="me-1" />
                                                                        }
                                                                        {searchMode == 'browse' && getCompanyStyleBadge(m)}
                                                                        {searchMode == 'leaderboards' && `${container.localeManager.numeral(m.driven_distance).format('0,0')} ${m.distance_unit}`}
                                                                        {searchMode == 'hardcore' && `${container.localeManager.numeral(m.points).format('0,0')} HP`}
                                                                    </div>
                                                                </Link>
                                                            </CardBody>
                                                        </Card>
                                                    )
                                                })}
                                            </div>
                                            <Paginator rowsPerPageSelected={pagination.perPage} rowsPerPage={[18, 36, 54, 72, 90]} handleRowsPerPageChange={handleRowsPerPageChange} forcePage={pagination.page - 1} totalPages={totalPages} handlePageClick={handlePageClick}></Paginator>
                                        </>
                                    }
                                    {!loading && companies.length == 0 &&
                                        <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 mt-5">
                                            {container.localeManager.strings.noCompanies}
                                        </div>
                                    }
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                    {!container.dataService.data.enablePremiumFeature() &&
                        <aside className="ad-column-placeholder">
                            {!window.overwolf &&
                                <BoxDynamicAd container={container} className="my-3" />
                            }
                        </aside>
                    }
                </Row>
            </LoadingOverlay>
        </div>
    );
}

export default Directory;