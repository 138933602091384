import { Container } from "reactstrap";
import Header from "../components/Header";
import ScrollToTop from "../components/ScrollToTop";
import AppRoutes from "../components/Routes";
import Footer from "../components/Footer";
import Drawer from "../components/Drawer";
import CompanyPresenceDrawer from "../../components/enterprise/CompanyPresenceDrawer";
import { useLocation } from "react-router";
import GameDashboard from "./GameDashboard";
import { useSearchParam } from "react-use";
import { useEffect, useState } from "react";
import BottomBar from "../components/BottomBar";
import NitroAdContainer from "../../ads/NitroAdContainer";
import config from "../../config";
import VTCMenu from "../components/VTCMenu";

const Layout = ({ container }) => {
  const location = useLocation();
  const inapp = useSearchParam("inapp");
  const [contentContainerHeight, setContentContainerHeight] = useState(null);

  useEffect(() => {
    if (location.pathname == "/map") {
      document.getElementsByTagName("body")[0].classList.add("livemap");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("livemap");
    }

    if (!container.dataService.data.enablePremiumFeature()) {
      window["nitroAds"].createAd("outstream-video", {
        refreshTime: 30,
        format: "floating",
        //demo: config.NITRO_ADS_DEMO,
      });
    }
  }, [location]);

  useEffect(() => {
    if (inapp === "true" || sessionStorage.getItem("inapp") === "true") {
      sessionStorage.setItem("inapp", "true");
      document.getElementsByTagName("body")[0].classList.add("inapp");
      setContentContainerHeight(window.outerHeight - 100);

      window.addEventListener("resize", () => {
        setContentContainerHeight(window.innerHeight - 100);
      });

      window.addEventListener("orientationchange", () => {
        setContentContainerHeight(window.innerHeight - 100);
      });
    }
  }, []);

  return (
    <>
      {location.pathname != "/gamedashboard" && (
        <>
          <NitroAdContainer
            container={container}
            id="sticky-footer"
            adProps={{
              format: "anchor-v2",
              anchor: "bottom",
              anchorPersistClose: false,
              anchorBgColor: "rgba(21,21,33, 0.8)",
            }}
          ></NitroAdContainer>
          <ScrollToTop></ScrollToTop>
          <Header container={container}></Header>
          <Container
            fluid
            className="content-container mb-7 min-h-600px pt-3"
            style={{ height: contentContainerHeight }}
          >
            <div className="my-5">
              <NitroAdContainer
                container={container}
                id="top-leaderboard"
                adProps={{
                  sizes: [
                    ["728", "90"],
                    ["970", "90"],
                    ["320", "50"],
                    ["320", "100"],
                  ],
                  mediaQuery:
                    "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px)",
                }}
              ></NitroAdContainer>
              <NitroAdContainer
                container={container}
                id="top-leaderboard-mobile"
                adProps={{
                  sizes: [
                    ["320", "100"],
                    ["320", "50"],
                  ],
                  mediaQuery: "(min-width: 320px) and (max-width: 767px)",
                }}
              ></NitroAdContainer>
            </div>
            <AppRoutes container={container}></AppRoutes>

            <div className="my-5">
              <NitroAdContainer
                container={container}
                id="bottom-leaderboard"
                adProps={{
                  sizes: [
                    ["728", "90"],
                    ["970", "90"],
                    ["970", "250"],
                    ["300", "250"],
                  ],
                  mediaQuery:
                    "(min-width: 1025px), (min-width: 768px) and (max-width: 1024px)",
                }}
              ></NitroAdContainer>
              <NitroAdContainer
                container={container}
                id="bottom-leaderboard-mobile"
                adProps={{
                  sizes: [
                    ["300", "250"],
                    ["320", "100"],
                    ["320", "50"],
                  ],
                  mediaQuery: "(min-width: 320px) and (max-width: 767px)",
                }}
              ></NitroAdContainer>
            </div>

            <BottomBar container={container}></BottomBar>
          </Container>
          <Footer container={container}></Footer>
          <Drawer container={container}></Drawer>
          <CompanyPresenceDrawer container={container}></CompanyPresenceDrawer>
        </>
      )}
      {location.pathname == "/gamedashboard" && (
        <GameDashboard container={container}></GameDashboard>
      )}
    </>
  );
};

export default Layout;
